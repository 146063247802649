import { addressToMultiLineString, addressToLine1 } from '../utils/format'

export const sampleAsset = {
  confidentiality_agreement_id: 1,
  ca_signed_date: '2022-01-18T19:12:41Z',
  listing_id: 20017605,
  property_name: '7709 Athens Road',
  property_type: 'Special Purpose',
  base_asset_address: {
    street_number: '7709',
    street_name: 'Athens Rd',
    municipal_subdivision: 'Stokesdale',
    municipality: 'Stokesdale',
    country_subdivision: 'NC',
    postal_code: '27357    ',
    country_code: 'US',
    id: 193067475,
    address_standardization_result: 'Successfully Standardized',
    address_standardization_result_id: 1,
    region: 'SouthEast',
    canonical_address_hash: '36f865287694e8bf91de66889f0d51e2',
  },
  thumbnail_media_uri:
    'https://ahtstcdn1.costar.com/i2/Nhubr2dFtRw4Jr91iZ6YiVfd66NlEcAVJFNL7h-nb-M/116/image.jpg',
  is_buyer_broker_coop_available: false,
  custom_banner_text: 'Test11',
  is_opportunity_zone: false,
  lender_financing_available: true,
  is_absolute_sale: false,
  listing_end_date: '2022-03-30T19:00:00',
  listing_status: 'ACTIVE',
}
export const adaptAssetFromAPI = (asset) => {
  return {
    listingStatus: {
      status: asset.listing_status,
    },
    listingId: asset.listing_id,
    name: asset.property_name,
    fullAddress: addressToMultiLineString(asset.base_asset_address),
    address: {
      cc: asset.base_asset_address.country_code,
      city: asset.base_asset_address.municipality,
      post: asset.base_asset_address.postal_code,
      sc: asset.base_asset_address.country_subdivision,
      street: addressToLine1(asset.base_asset_address),
    },
    sale: {
      auctionEndDate: asset.listing_end_date,
      auctionStartDate: asset.auction_start_date_utc,
      auctionStartingBid2: asset.starting_bid,
    },
    propType: asset.property_type,
    primaryPhoto: {
      uri: asset.thumbnail_media_uri,
    },
    opportunityZone: asset.is_opportunity_zone,
    isBuyerBrokerCoopAvailable: asset.is_buyer_broker_coop_available,
    isFinancingAvailable: asset.is_lender_financing_available,
    isAuctionAbsolute: asset.is_absolute_sale,
    ldpUrl: asset.ldp_url,
  }
}
