/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import React, { Fragment, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { Row, Col, Icon } from 'antd'
import {
  ConfirmEmailSplash,
  RegistrationForm,
  Typography,
  styles,
} from '@ten-x/fe-lib-ui2020'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import analytics from '../../services/analytics'
import MSG from '../../constants/messages'

const { Title, Paragraph } = Typography
const { styled } = styles

export const SignupPageComponent = ({ location }) => {
  const [userEmail, setUserEmail] = useState('')
  const [showConfirmEmailContent, setShowConfirmEmailContent] = useState(false)

  useEffect(() => {
    analytics.onPageLoadCreateAccount()
  }, [])

  function onRegistrationSucces(values, userId) {
    // We don't want to cancel registration flow if analytics fail, swallow error
    try {
      analytics.onCreateAccount(values.marketing_opt, values, userId)
    } catch (error) {
      console.error(error)
    }
    setUserEmail(values.email)
    setShowConfirmEmailContent(true)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const qs = new URLSearchParams(location.search)
  const isTenxUserButNotCostar = qs.get('showLegacyWarning') === 'true'

  return (
    <SignupPageContainer>
      <Helmet title={MSG.SIGN_UP} />
      <SignupContentContainer>
        <Col span={24} md={18} lg={14} xl={12} xxl={10}>
          {showConfirmEmailContent ? (
            <ConfirmationEmailSplashContainer>
              <ConfirmEmailSplash email={userEmail} />
            </ConfirmationEmailSplashContainer>
          ) : (
            <Fragment>
              <TitleContainer color="black">Sign Up</TitleContainer>
              {isTenxUserButNotCostar ? (
                <WarningBannerContainer>
                  <ExclamationCircleIcon type="exclamation-circle" />
                  <div>
                    Ten-X is asking for your information again to tie your
                    existing Ten-X account with our new authentication system.
                    Your previously saved Ten-X data will not be affected.
                  </div>
                </WarningBannerContainer>
              ) : null}
              <SectionParagraph size="large" color="black">
                Welcome to Ten-X, the industry leader in online commercial real
                estate transactions. Signing up only takes a minute and is free.
              </SectionParagraph>
              <RegistrationForm
                showLoginLink
                onFormSubmitSuccess={onRegistrationSucces}
                submitButtonLabel="SIGN UP"
              />
            </Fragment>
          )}
        </Col>
      </SignupContentContainer>
    </SignupPageContainer>
  )
}

export const SignupPageContainer = styled.div`
  margin-top: 102px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    margin-top: 132px;
  }
`

export const SignupContentContainer = styled(Row)(
  ({ theme }) => `
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  padding: ${theme.spacing(8, 0)};
  text-align: left;

  a:visited {
    color: ${theme.palette.white}
  }

  a:hover {
    color: ${theme.palette.blue}
  }
`
)

export const TitleContainer = styled(Title)(
  ({ theme }) => `
  &.ant-typography {
    margin-bottom: ${theme.spacing(7)};
  }
`
)

export const SectionParagraph = styled(Paragraph)(
  ({ theme }) => `
  &.ant-typography {
    margin-bottom: ${theme.spacing(4)};
  }
`
)

export const ConfirmationEmailSplashContainer = styled.div`
  text-align: left;
`

export const WarningBannerContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 3)};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0029em;
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  color: ${({ theme }) => theme.palette.white};
  border: 1px solid ${({ theme }) => theme.palette.red};
`

export const ExclamationCircleIcon = styled(Icon)`
  color: ${({ theme }) => theme.palette.red};
  font-size: 35px;
  margin-right: ${({ theme }) => theme.spacing(3)};
`

SignupPageComponent.propTypes = {
  location: PropTypes.object.isRequired,
}

export default withRouter(SignupPageComponent)
