import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import { Spin, Row, Col, Icon } from 'antd'
import styled from '@emotion/styled'
import { SectionTitle } from '@ten-x/fe-lib-ui2020'
import ClassNames from 'classnames/bind'
import { ServerNowProvider, ServerTimeProvider } from '@ten-x/fe-lib-bidding'

import types from '../../../../constants/prop_types'
import UserActions from '../../../../actions/user'
import { request as getFeaturesProperties } from '../../../../actions/assetsActions'
import SavedAssetsService from '../../../../services/saved_assets'
import {
  FEATURED_PROPERTIES,
  TRENDING_NOW,
  RECOMMENDED_FOR_YOU,
  VIEW_ALL_PROPERTIES,
} from '../../../../constants/statics'

import PropertyCarouselContainer from './propertyCarousel'
import breakpoints from './breakpoints'
import cls from './styles.scss'

const cx = ClassNames.bind(cls)

class FeaturedProperties extends Component {
  static propTypes = {
    user: types.user,
    assets: PropTypes.object,
    dispatch: PropTypes.func,
    onSaveAsset: PropTypes.func.isRequired,
    featureFlags: PropTypes.object,
  }

  componentDidMount() {
    this.fetchInitialAssets()
    this.fetchSavedAssets()
  }

  fetchSavedAssets = () => {
    const { user, dispatch } = this.props
    if (user && user.logged_in) {
      dispatch(UserActions.getSavedAssets())
    }
  }

  fetchInitialAssets() {
    const { dispatch } = this.props
    dispatch(getFeaturesProperties())
  }

  toggleSave = (asset) => {
    this.props.onSaveAsset(asset)
  }

  render() {
    const { assets, user, featureFlags } = this.props
    const showLoading = !assets.all || (assets.all && assets.all.loading)

    const assetsList = _get(assets, 'all.list', [])
    const recommendedForYou = _get(assets, 'all.recommendedListingsForYou')
    const savedAssets = SavedAssetsService.getSavedAssetIdsObject(
      user.saved_assets
    )

    let personalizationType = FEATURED_PROPERTIES
    if (typeof recommendedForYou !== 'undefined') {
      personalizationType = recommendedForYou
        ? RECOMMENDED_FOR_YOU
        : TRENDING_NOW
    }

    return (
      <div
        data-elm-id="featured-properties-container"
        className={cx('featured-section')}
      >
        <Row>
          <Col xs={24} xl={12}>
            <SectionTitle color="black" textAlign="left" size="large">
              <TitleContainer>
                {!showLoading && personalizationType}
              </TitleContainer>
            </SectionTitle>
          </Col>
          <Col xs={24} xl={12}>
            <FeaturedPropertiesButtons>
              <a data-elm-id="view-all-btn" href="/search">
                <ArrowIcon size="20px" type="arrow-right" />
                <span>{VIEW_ALL_PROPERTIES}</span>
              </a>
            </FeaturedPropertiesButtons>
          </Col>
        </Row>
        {showLoading ? (
          <div className={cx('loading-properties-container')}>
            <Spin size="large" tip="Loading properties..." />
          </div>
        ) : (
          (assetsList.length && (
            <Fragment>
              <div className={cx('listing-holder')}>
                <ServerTimeProvider>
                  <ServerNowProvider>
                    <PropertyCarouselContainer
                      assetsList={assetsList}
                      responsiveBreakPoints={breakpoints}
                      savedAssets={savedAssets}
                      toggleSaveAsset={this.toggleSave}
                      personalizationType={personalizationType}
                      featureFlags={featureFlags}
                    />
                  </ServerNowProvider>
                </ServerTimeProvider>
              </div>
            </Fragment>
          )) || (
            <div>
              We are sorry, we couldn't find any properties at the moment
            </div>
          )
        )}
      </div>
    )
  }
}

export const TitleContainer = styled.h2`
  font-size: 50px;
  line-height: 75px;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 36px;
    line-height: 46px;
  }
`

export const ArrowIcon = styled(Icon)`
  color: ${({ theme }) => theme.palette.black};
  transform: scale(1.5);
`
export const FeaturedPropertiesButtons = styled.div`
  display: inline-block;
  width: 100%;
  text-align: right;
  margin-top: 72px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    margin-top: 0;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    text-align: left;
    padding-left: 70px;
    &:not(:first-child) {
      margin-left: 2.5%;
    }
  }

  a,
  a:visited {
    text-align: left;
    position: relative;
    display: inline-block;
    padding: 13px 0px;
    margin-right: 45px;

    @media (max-width: $screen-sm) {
      &:not(:first-child) {
        margin-top: 18px;
      }
    }

    > span {
      font-size: 15px;
      line-height: 48px;
      text-transform: uppercase;
      font-weight: ${({ theme }) => theme.font.weight.normal};
      color: #000;
      padding-left: 12px;
    }

    &:hover {
      svg,
      span {
        opacity: 0.7;
      }
    }
  }
`

export default FeaturedProperties
