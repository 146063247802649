import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import logger from '@ten-x/logger'

// A simple wrapper on top of ReactRouter.Route that checks that there is
// a logged in user before rendering MyComponent.
// If the user is not logged in
// then it will redirect to the login
//
// NOTE that this works client side as well as server side, so this is the single
// interface for protecting routes in both the backend and the front end
//
// Usage
//
// ```
//  <PrivateRoute path="/user" component={MyComponent} />
// ```

const PrivateRoute = ({
  // This is the logged in user if any
  user,
  // The component to render
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (!Component) return null

      const { location } = props

      const [hasAccess, reason] = isAllowed(user)
      if (hasAccess) {
        return <Component {...props} />
      }

      // Redirect the user to login page if it's not logged in. We're not
      // checking the reason since in this app is just needed to account
      // only for the user logged in or not
      const redirectTo = '/login'

      logger.info(
        `Redirecting to ${redirectTo}. Reason: ${reason}`,
        'Component: PrivateRoute',
        location,
        user
      )

      return (
        <Redirect
          to={{
            pathname: redirectTo,
            search: `?back=${location.pathname}`,
            state: {
              from: location,
              component: Component.displayName,
            },
          }}
        />
      )
    }}
  />
)

PrivateRoute.propTypes = {
  user: PropTypes.shape({
    logged_in: PropTypes.bool.isRequired,
  }),
  component: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}

// For now we just need to check if the user is logged in or not
const isAllowed = (user) => {
  if (!user.logged_in) {
    return [false, 'NOT_LOGGED_IN']
  }
  return [true]
}

export default PrivateRoute
export { isAllowed }
