/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { PropertyCarousel } from '@ten-x/fe-lib-ui2020'
import { useServerSharedTimeTick } from '@ten-x/fe-lib-bidding'
import moment from 'moment'
import _get from 'lodash/get'

function hasBuyNowAnnounced(now, announcementTime) {
  if (!announcementTime) return false
  const announcementDateMoment = moment.utc(announcementTime).local()
  return announcementDateMoment.isSameOrBefore(now)
}

function hasBuyNowEnded(now, endTime) {
  if (!endTime) return false
  const end = moment.utc(endTime).local()
  return end.isSameOrBefore(now)
}

const PropertyCarouselContainer = ({
  assetsList,
  responsiveBreakPoints,
  savedAssets,
  toggleSaveAsset,
  personalizationType,
  featureFlags,
}) => {
  const now = useServerSharedTimeTick()
  const buyNowFlag = _get(featureFlags, 'fe.cre.buy-now', false)
  const placards = assetsList.map((asset) => {
    let buyNowEnabled
    let buyNowOpenDateTime
    if (buyNowFlag && asset?.buyNow?.isEnabled) {
      const { announcementDateTime, endDateTime, openDateTime } = asset.buyNow

      const buyNowAnnounced = hasBuyNowAnnounced(now, announcementDateTime)
      const buyNowEnded = hasBuyNowEnded(now, endDateTime)

      // Only show the Buy Now logo if feature flag enabled, Buy Now enabled, announced, and haven't ended
      buyNowEnabled = buyNowAnnounced && !buyNowEnded
      buyNowOpenDateTime = openDateTime
    }

    return {
      ...asset,
      buyNowEnabled,
      buyNowOpenDateTime,
    }
  })

  return (
    <PropertyCarousel
      placards={placards}
      responsiveBreakPoints={responsiveBreakPoints}
      savedAssets={savedAssets}
      toggleSaveAsset={toggleSaveAsset}
      personalizationType={personalizationType}
    />
  )
}

PropertyCarouselContainer.propTypes = {
  assetsList: PropTypes.any,
  responsiveBreakPoints: PropTypes.shape({
    breakpoint: PropTypes.number,
    settings: PropTypes.shape({
      slidesToShow: PropTypes.number,
      slidesToScroll: PropTypes.number,
    }),
  }),
  savedAssets: PropTypes.any,
  toggleSaveAsset: PropTypes.any,
  personalizationType: PropTypes.string,
  featureFlags: PropTypes.object,
}

export default PropertyCarouselContainer
