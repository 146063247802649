const ENTERPRISE_IMAGES = [
  {
    key: 'cushman',
    url: 'https://cre-prd-img.imgix.net/homepage%2Fbrokers%2Fcushman.v1.png?auto%3Dformat',
  },
  {
    key: 'newmark',
    url: 'https://cre-prd-img.imgix.net/homepage/brokers/Newmark_Group_Inc_Logo-BLACK.png?auto%3Dformat&w=200',
  },
  {
    key: 'colliers',
    url: 'https://cre-prd-img.imgix.net/homepage%2Fbrokers%2FColliers%20BW.png?auto%3Dformat&h=72',
  },
  {
    key: 'jll',
    url: 'https://cre-prd-img.imgix.net/homepage%2Fbrokers%2Fjll.v1.png?auto%3Dformat',
  },
  {
    key: 'cbre',
    url: 'https://cre-prd-img.imgix.net/homepage%2Fbrokers%2Fcbre.v1.png?auto%3Dformat',
  },
  {
    key: 'keller-williams',
    url: 'https://cre-prd-img.imgix.net/homepage%2Fbrokers%2Fkeller-williams.v1.png?auto%3Dformat&h=72',
  },
]

export { ENTERPRISE_IMAGES }
