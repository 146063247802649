/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { Typography, Button } from '@ten-x/fe-lib-ui2020'

import { formatPhoneNumber } from '../../utils/format'
import MSG from '../../constants/messages'

const { Title, Paragraph } = Typography

const Success = (props) => {
  const phoneNumber = MSG.TENX_PHONE_NUMBER
  const formatedPhoneNumber = formatPhoneNumber(phoneNumber)
  const { loginBackToURL } = props

  return (
    <Fragment>
      <CongratsTitle color="black" data-elm-id="confirm-account-success-title">
        {MSG.CONFIRM_ACCOUNT_CONGRAT}
      </CongratsTitle>
      <QuestionsTitle
        level={3}
        color="black"
        data-elm-id="confirm-account-sub-title"
      >
        {MSG.QUESTIONS_Q}
      </QuestionsTitle>
      <Paragraph color="black" data-elm-id="confirm-account-message">
        {MSG.CALL_US} <a href={`tel:${phoneNumber}`}>{formatedPhoneNumber}</a>,{' '}
        {MSG.CALL_HOURS}
      </Paragraph>
      <LoginButton
        data-elm-id="confirm-account-login-link"
        type="primary"
        block
        href={loginBackToURL}
      >
        {MSG.LOG_IN}
      </LoginButton>
    </Fragment>
  )
}

Success.propTypes = {
  loginBackToURL: PropTypes.string.isRequired,
}

export const CongratsTitle = styled(Title)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(6)};

  @media (max-width: ${theme.screenBreakpoints.lg}px) {
    &.ant-typography {
      font-size: 32px;
    }
  }

  @media (max-width: ${theme.screenBreakpoints.sm}px) {
    &.ant-typography {
      font-size: 26px;
    }
  }
`
)

export const QuestionsTitle = styled(Title)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`

export const LoginButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(4)};
`

export default Success
