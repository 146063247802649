/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { styles, Button, Typography } from '@ten-x/fe-lib-ui2020'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { notification, Row, Col } from 'antd'

import ProcessingOverlay from '../../components/processingOverlay'
import { confirmUser } from '../../data_providers/user'
import MSG from '../../constants/messages'

const { styled } = styles
const { Paragraph, Title } = Typography

const ConfirmEmailUpdate = ({ location }) => {
  const qs = new URLSearchParams(location.search)

  const [confirmedUser, setConfirmedUser] = useState(false)
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    const uid = qs.get('uid')
    let mounted = true

    async function confirmUserEmail() {
      if (uid) {
        try {
          await confirmUser(null, { reset_token: uid })
          mounted && setConfirmedUser(true)
        } catch (error) {
          console.error(error)
          const status = get(error, 'status')

          if (status === 412) {
            setErrorMessage(MSG.RESET_EMAIL_TOKEN_EXPIRED)
          } else if (status === 400) {
            setErrorMessage(MSG.RESET_EMAIL_ALREADY_CHANGED)
          } else {
            notification.error({
              message: MSG.RESET_EMAIL_ERROR_TITLE,
              description: MSG.RESET_EMAIL_ERROR_BODY,
            })
          }
        }
      }
      mounted && setLoading(false)
    }

    confirmUserEmail()

    return () => {
      mounted = false
    }
  }, [])

  if (loading) {
    return <ProcessingOverlay />
  }

  return (
    <Fragment>
      <Helmet title={MSG.RESET_EMAIL} />
      <ContentContainer data-elm-id="reset-email-container">
        <ResponsiveCol span={20} md={12} lg={10} xl={8} xxl={6}>
          {confirmedUser ? (
            <Fragment>
              <TitleContainer
                color="black"
                data-elm-id="reset-email-success-title"
              >
                {MSG.RESET_EMAIL}
              </TitleContainer>
              <SectionParagraph color="black" size="large">
                {MSG.RESET_EMAIL_SUCCESS_LINE1}
              </SectionParagraph>
              <SectionParagraph color="black" size="large">
                {MSG.RESET_EMAIL_SUCCESS_LINE2}
              </SectionParagraph>
              <LoginButton type="primary" href="/commercial/login/">
                LOGIN
              </LoginButton>
            </Fragment>
          ) : (
            <TitleContainer
              color="black"
              data-elm-id="reset-email-invalid-title"
            >
              {errorMessage || MSG.RESET_EMAIL_INVALID_REQUEST}
            </TitleContainer>
          )}
        </ResponsiveCol>
      </ContentContainer>
    </Fragment>
  )
}

ConfirmEmailUpdate.propTypes = {
  location: PropTypes.object,
}

const ContentContainer = styled(Row)`
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
`

const ResponsiveCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TitleContainer = styled(Title)`
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    &.ant-typography {
      font-size: 32px;
    }
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    &.ant-typography {
      font-size: 26px;
    }
  }
`

const SectionParagraph = styled(Paragraph)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`

const LoginButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(6)};
`

export default ConfirmEmailUpdate
