/* eslint-disable prettier/prettier */
/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Header, Footer, styles } from '@ten-x/fe-lib-ui2020'

import types from '../../constants/prop_types'

const { withThemeProvider } = styles

const Main = styled.main(
  ({ theme }) => `
  background-color: ${theme.palette.grayLight};
  width: 100%;
  position: relative;
  min-height: 736px;

  padding: ${theme.spacing(0, 3)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &::before {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    top: 2px;
    background-image: url(https://cre-prd-img.imgix.net/site%2Fstatic%2Fhero-buildings.v1.png?auto=format,compress&w=451&h=277);
    background-repeat: no-repeat;
    background-position: right bottom;

    @media (max-width: ${theme.screenBreakpoints.sm}px) {
      background-image: unset;
    }
  }
`
)

export const FixedHeader = styled(Header)`
  margin-bottom: -102px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    margin-bottom: -132px;
  }
`

const Layout = ({
  featureFlags,
  urlListFooter,
  urlListHeader,
  user,
  children,
}) => {
  const urlList = { ...urlListFooter, ...urlListHeader }

  return (
    <Fragment>
      <FixedHeader
        transparentMode
        transparentDarkFont
        featureFlags={featureFlags}
        urlList={urlList}
        user={user}
      />
      <Main>{children}</Main>
      <Footer urlList={urlList} />
    </Fragment>
  )
}

Layout.propTypes = {
  user: types.user.isRequired,
  featureFlags: PropTypes.object.isRequired,
  urlListFooter: PropTypes.object.isRequired,
  urlListHeader: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
}

const mapStateToProps = (store) => {
  return {
    user: store.user,
    featureFlags: store.config.featureFlags,
    urlListFooter: store.config.uiUrls && store.config.uiUrls.footer,
    urlListHeader: store.config.uiUrls && store.config.uiUrls.header,
  }
}

const connectedLayout = connect(mapStateToProps)(Layout)
export default withThemeProvider(connectedLayout)
