/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import PropTypes from 'prop-types'
import { SearchMenuBar } from '@ten-x/fe-lib-ui2020'
import { Row, Col } from 'antd'
import React from 'react'
import _get from 'lodash/get'

import { PROPERTY_TYPES } from './heroData'
import { searchRedirect } from './searchRedirect'
import {
  Main,
  MainSponsor,
  Paragraph,
  SearchContainer,
  Container,
  Title,
  SponsorTitle,
  SponsorSubTitle,
  SponsorContainer,
  SloganContainer,
  SponsorSearchContainerColumn,
} from './heroStyles'

const Hero = ({ featureFlags }) => {
  function onSearch({ searchInput, propertySelection, assetType }) {
    searchRedirect(searchInput, propertySelection, assetType)
  }

  const showSponsorPage = _get(
    featureFlags,
    'fe.tenx.enable-sponsor-page',
    false
  )

  return (
    <React.Fragment>
      {showSponsorPage ? (
        <MainSponsor>
          <SponsorContainer data-elm-id="hero-container">
            <Row gutter={24}>
              <Col sm={0} md={8} />
              <SponsorSearchContainerColumn md={24}>
                <SponsorTitle color="white" level={2} />
                <SponsorSubTitle>Ten-X it</SponsorSubTitle>
                <Paragraph>
                  Empowering the commercial real estate industry to close deals
                  faster, better and with more certainty.
                </Paragraph>
              </SponsorSearchContainerColumn>
            </Row>
            <Row gutter={24}>
              <SearchContainer>
                <SearchMenuBar
                  menuBar
                  showSearchFilters
                  title="Search Properties"
                  onSearch={onSearch}
                  propertyTypes={PROPERTY_TYPES}
                />
              </SearchContainer>
            </Row>
          </SponsorContainer>
        </MainSponsor>
      ) : (
        <Main>
          <Container data-elm-id="hero-container">
            <Row justify="center">
              <SloganContainer xs={0} md={24}>
                <Title>THE SMARTER, FASTER, BETTER way</Title>
                <Title>to transact commercial real estate</Title>
              </SloganContainer>
              <SloganContainer xs={24} md={0}>
                <h1>
                  <Title>
                    THE SMARTER, FASTER, BETTER way to transact commercial real
                    estate
                  </Title>
                </h1>
              </SloganContainer>
            </Row>
          </Container>
          <SearchContainer>
            <SearchMenuBar
              menuBar
              showAssetTypes={false}
              showSearchFilters
              title="Search Properties"
              onSearch={onSearch}
              propertyTypes={PROPERTY_TYPES}
            />
          </SearchContainer>
        </Main>
      )}
    </React.Fragment>
  )
}

Hero.propTypes = {
  featureFlags: PropTypes.object.isRequired,
}

export default Hero
