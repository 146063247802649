/* istanbul ignore file */
import { connect } from 'react-redux'
import { ErrorPage } from '@ten-x/fe-lib-ui2020'

export default connect((store, ownProps) => {
  // For now we are not doing any special error handling
  const statusCode =
    new URLSearchParams(ownProps.location.search).get('code') || 500
  const footerUrls = store.config?.uiUrls?.footer || {}
  const headerUrls = store.config?.uiUrls?.header || {}

  return {
    error: { statusCode, message: 'Unknown Error' },
    ldFlags: store.ldFlags || {},
    user: store.user || {},
    urlList: { ...footerUrls, ...headerUrls },
  }
})(ErrorPage)
