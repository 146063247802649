import styled from '@emotion/styled'
import { Col } from 'antd'
import image from "./x-logo.svg"

export const SearchFilterContainer = styled.div`
  max-width: 1440px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
`

export const Paragraph = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 400px;
  font-size: 24px;
  line-height: 34px;
  color: white;
  margin-top: 39px;
  margin-bottom: 187px;
  float: right;
  max-width: 418px;
  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 13px;
    line-height: 22px;
    margin-top: 15px;
    margin-bottom: 17px;
    max-width: 240px;
  }
`

export const Container = styled.div`
  max-width: 1100px;
  width: 95%;
  margin: 20px auto 15px;
`

export const SponsorContainer = styled.div`
  max-width: 1440px;
  width: 95%;
  margin: 200px auto 15px;

  @media (max-width: 1080px) {
    //  margin-top: 400px;
  }
  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    margin-top: 380px;
  }
`

export const SearchContainer = styled.div`
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  padding-top: 16px;
  width: 95%;
  @media screen and (min-width: ${({ theme }) =>
      theme.screenBreakpoints.sm}px) {
    bottom: 0;
    left: 0;
    margin: 20px auto 15px;
    max-width: 1100px;
    position: absolute;
    right: 0;
    transform: translateY(65%);
  }
`

export const Title = styled.div`
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  font-size: 64px;
  line-height: 75px;
  background: ${({ theme }) => theme.palette.costarGradient};
  -webkit-background-clip: text;

  /* Safari 10.1+ */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      display: inline-block;
      font-size: 66px;
      line-height: 80px;
    }
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 40px;
    line-height: 50px;
    text-align: center;

    /* Safari 10.1+ */
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        font-size: 50px;
        line-height: 60px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 30px;
    line-height: 45px;
  }
`

export const SubTitle = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  font-size: 64px;
  line-height: 75px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 36px;
    line-height: 45px;
  }
`

export const SponsorSubTitle = styled.div`
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 155px;
  line-height: 187px;
  color: white;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 70px;
    line-height: 80px;
  }
`

export const SponsorTitle = styled.span`
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  font-size: 90px;
  line-height: 75px;
  -webkit-background-clip: text;
  padding-right: 10px;
  color: white;

  /* Safari 10.1+ */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      display: inline-block;
      font-size: 66px;
      line-height: 80px;
    }
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 68px;
    line-height: 70px;
    min-width: 20px;

    /* Safari 10.1+ */
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        font-size: 50px;
        line-height: 60px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 36px;
    line-height: 45px;
  }
`

export const SearchContainerColumn = styled(Col)`
  margin-top: 20px;
  max-width: 464px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    margin-top: 12px;
  }
`

export const SponsorSearchContainerColumn = styled(Col)`
  margin-top: 13px;
  text-align: right;
`

export const Main = styled.main(
  ({ theme }) => `
    background-image: linear-gradient(180deg,rgba(17,18,22,0.5) 7%,rgba(6,85,165,0.5) 50%,rgba(233,6,17,0.3) 74%),url(https://cre-prd-img.imgix.net/site%2Fstatic%2Fimages%2Fcity-image.v2.png);
    background-position: bottom;
    background-size: cover;
    min-height: 440px;
    padding-top: 70px;

    @media screen and (min-width: ${theme.screenBreakpoints.sm}px) {
      background-image: linear-gradient(175deg, rgba(17,18,22,0.5) 16%, rgba(6,85,165,0.5) 39%, rgba(233,6,17,0.3) 64%), url(https://cre-prd-img.imgix.net/site%2Fstatic%2Fimages%2Fcity-image.v2.png);
      background-repeat: no-repeat;
      min-height: 475px;
      padding-top: 120px; 
      position: relative;
      &::before {
        background-image: url(${image});
        background-repeat: no-repeat;
        background-position: right top;
        background-size: 1600px;
        content: ' ';
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    @media screen and (min-width: ${theme.screenBreakpoints.md}px) {
      min-height: 500px;

      &::before {
        background-size: 1700px;
      }
    }
  
  `
)

export const MainSponsor = styled.div`
  background-image: linear-gradient(177deg, #0054a6 0%, #ce2027 90%),
    url(https://cre-prd-img.imgix.net/site%2Fstatic%2Fbuildings.v1.png),
    url(https://cre-prd-img.imgix.net/site%2Fstatic%2Fwhite-XVector-Right-AboutUs-v2.v1.svg);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: color;
  height: 1080px;
  background-position: bottom, right, right;
  min-height: 800px;
  overflow: hidden;
  padding: 0 80px 0px 80px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    padding: 0;
    height: 850px;
  }

  &:before {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    left: 0;
    background-image: url(https://cre-prd-img.imgix.net/site%2Fstatic%2FtalenKeegan.v4.png);
    background-repeat: no-repeat;
    background-position: left;
    background-size: 756px;
    clip-path: polygon(0px 1704px, 745px 556px, 405px 0px, 0px 0px);

    @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
      clip-path: polygon(0px 1704px, 745px 556px, 405px 0px, 0px 0px);
    }

    @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
      background-position: -25px 0px;
      background-size: 740px;
      clip-path: polygon(0px 1704px, 692px 556px, 405px 0px, 0px 0px);
    }

    @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
      background-position: -141px 0px;
      background-size: 733px;
      //   background-position: -100px 37px;
      clip-path: polygon(0px 1704px, 590px 556px, 306px 0px, 0px 0px);
    }

    @media (max-width: ${({ theme }) => theme.screenBreakpoints.xs}px) {
      background-position: -127px 0px;
    }
  }
`

export const MobileSearchContainerColumn = styled(Col)`
  margin-top: 24px;
`

export const SloganContainer = styled(Col)`
  margin-top: 50px;
  text-align: center;
  /* Safari 10.1+ */

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      margin-top: 30px;
    }
  }
`
