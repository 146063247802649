import store from '../store'
import { addSavedAsset, deleteSavedAsset } from '../data_providers/user'
import UserActions from '../actions/user'
import SharedMessageActions from '../actions/sharedMessage'
import { SHARED_MESSAGE_AUTO_CLOSE_DELAY_LONG } from '../constants/statics'
import MSG from '../constants/messages'

export default {
  saveOrDeleteAsset(user, asset) {
    const savedEnterpriseIds = this.getSavedAssetIds(user.saved_assets)

    const { enterpriseListingId = null } = asset
    if (
      savedEnterpriseIds &&
      !savedEnterpriseIds.includes(enterpriseListingId)
    ) {
      return this.saveNewAsset(asset, 'home_properties')
    } else {
      return this.deleteSavedAsset(asset)
    }
  },
  saveNewAsset: (asset, sectionName) => {
    store().dispatch(UserActions.updateSavedAssetList(asset))
    return addSavedAsset(null, asset.enterpriseListingId)
      .then(() => {
        store().dispatch(
          SharedMessageActions.update({
            role: 'information',
            title: MSG.ASSET_SAVED_TITLE,
            message: MSG.ASSET_SAVED,
            isVisible: true,
          })
        )
        store().dispatch(
          SharedMessageActions.delayedClose(
            SHARED_MESSAGE_AUTO_CLOSE_DELAY_LONG
          )
        )
        return store().dispatch(UserActions.getSavedAssets())
      })
      .catch(() => {
        store().dispatch(
          SharedMessageActions.update({
            role: 'information',
            title: MSG.ASSET_SAVE_FAILED_TITLE,
            message: MSG.ASSET_SAVE_FAILED,
            isVisible: true,
          })
        )
        store().dispatch(
          SharedMessageActions.delayedClose(
            SHARED_MESSAGE_AUTO_CLOSE_DELAY_LONG
          )
        )
        return store().dispatch(UserActions.getSavedAssets())
      })
  },
  deleteSavedAsset: (asset) => {
    store().dispatch(UserActions.updateSavedAssetList(asset))
    return deleteSavedAsset(null, asset.enterpriseListingId)
      .then((res) => {
        store().dispatch(
          SharedMessageActions.update({
            role: 'information',
            title: MSG.ASSET_REMOVED_TITLE,
            message: MSG.ASSET_REMOVED,
            isVisible: true,
          })
        )
        store().dispatch(
          SharedMessageActions.delayedClose(
            SHARED_MESSAGE_AUTO_CLOSE_DELAY_LONG
          )
        )
        return store().dispatch(UserActions.getSavedAssets())
      })
      .catch(() => {
        store().dispatch(
          SharedMessageActions.update({
            role: 'information',
            title: MSG.ASSET_REMOVE_FAILED_TITLE,
            message: MSG.ASSET_REMOVE_FAILED,
            isVisible: true,
          })
        )
        store().dispatch(
          SharedMessageActions.delayedClose(
            SHARED_MESSAGE_AUTO_CLOSE_DELAY_LONG
          )
        )
        return store().dispatch(UserActions.getSavedAssets())
      })
  },
  getSavedAssetIds: (assetList) => {
    return (
      (assetList &&
        assetList.map((savedAsset) => savedAsset.enterpriseListingId)) ||
      []
    )
  },
  getSavedAssetIdsObject: (assetList) => {
    return (
      (assetList &&
        assetList.reduce((obj, item) => {
          obj[item.enterpriseListingId] = true
          return obj
        }, {})) ||
      {}
    )
  },
  // Special for when we get a request to save an asset before the user is actually
  // logged in and we don't know if they already have it saved or not so just save it
  saveAssetById: (assetId) => {
    return addSavedAsset(null, assetId)
      .then(() => {
        store().dispatch(
          SharedMessageActions.update({
            role: 'information',
            title: MSG.ASSET_SAVED_TITLE,
            message: MSG.ASSET_SAVED,
            isVisible: true,
          })
        )
        return store().dispatch(
          SharedMessageActions.delayedClose(
            SHARED_MESSAGE_AUTO_CLOSE_DELAY_LONG
          )
        )
      })
      .catch(() => {
        store().dispatch(
          SharedMessageActions.update({
            role: 'information',
            title: MSG.ASSET_SAVE_FAILED_TITLE,
            message: MSG.ASSET_SAVE_FAILED,
            isVisible: true,
          })
        )
        store().dispatch(
          SharedMessageActions.delayedClose(
            SHARED_MESSAGE_AUTO_CLOSE_DELAY_LONG
          )
        )
      })
      .finally(() => {
        store().dispatch(UserActions.getSavedAssets())
      })
  },
}
