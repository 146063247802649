const cookie = require('cookie')
const fetch = require('@ten-x/core-utils/src/helpers/fetch')
const coreUtilsConstants = require('@ten-x/core-utils/src/constants/cookies')
const configment = require('@ten-x/configment')

const helper = require('./helper')

const APIAUTH_CONSTANT = coreUtilsConstants.AUTHENTICATION_COOKIE

const AuthProvider = {
  logout: ({ req }) => {
    let authCookie
    let authCookieDict
    let cookieDomain

    try {
      const { getCurrentDomain, authToken } =
        require('@ten-x/core-utils').helpers

      authCookie = req.cookies[APIAUTH_CONSTANT]
      authCookieDict = authToken.getAuthCookieDict(authCookie)
      cookieDomain = getCurrentDomain(req)
    } catch (e) {
      authCookie = ''
      authCookieDict = ''
      cookieDomain = ''
    }

    const apiUrl = `${configment.get('PAL_CRE_URL')}/pal/cre/v1/auth/logout/`
    return fetch(apiUrl, {
      req,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        cookie_domain: cookieDomain,
        cookie: cookie.serialize(APIAUTH_CONSTANT, authCookie),
      },
      body: JSON.stringify({
        access_token: authCookieDict.access_token,
        refresh_token: authCookieDict.refresh_token,
      }),
    })
      .then((res) => res.body)
      .catch(() => {
        return {}
      })
  },

  resendConfirmEmail: ({ req, email }) => {
    const apiUrl = helper.getUrl('resendConfirmEmail')
    const data = {
      username: email,
    }

    return fetch(apiUrl, {
      req,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((res) => res.body)
      .catch((err) => {
        throw err.body
      })
  },

  confirmUser: ({ req, args }) => {
    const apiUrl = helper.getUrl('confirmUser')
    const data = {
      encoded_username: args.invitationToken,
    }

    return fetch(apiUrl, {
      req,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((res) => res.body)
      .catch((err) => {
        throw err.body
      })
  },
}

module.exports = AuthProvider
