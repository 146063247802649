/**
 * TODO: Better testing this please. Can be done with the next GTM work
 */
import analytics, { gtm } from '@ten-x/app-analytics'
import md5 from 'md5'
import _get from 'lodash/get'

import store from '../store'

import analyticsHelper from './analyticsHelper'

/**
 * Page data for analytics (Trying to standardize on a common pattern. Using the PDP project as a guide)
 */
const pageData = {
  home: {
    pageName: 'home',
    channel: 'home',
    spec_version: 'home|1',
  },
  login: {
    pageName: 'login',
    channel: 'account',
    spec_version: 'login|1',
  },
  createAccount: {
    pageName: 'create_account',
    channel: 'account',
    spec_version: 'create_account|1',
  },
  confirmAccount: {
    pageName: 'confirm_account',
    channel: 'account',
    spec_version: 'confirm_account|1',
  },
  userInvite: {
    pageName: 'user_invite',
    channel: 'account',
    spec_version: 'user_invite|1',
  },
  resendConfirmEmail: {
    pageName: 'resend_confirm_email',
    channel: 'account',
    spec_version: 'resend_confirm_email|1',
  },
}

function _onPageLoad(addedContext, heapPageName = '') {
  const state = store().getState()
  const { user, config } = state

  if (config && !!config.analytics) {
    analytics
      .init(config, 'cre')
      .then(() => {
        return analytics.firePageTrackingWithContext(
          'cre',
          user,
          Object.assign(
            {
              lob: 'cre',
              action: 'Page Visited',
            },
            addedContext
          )
        )
      })
      .catch(function (err) {
        console.error(
          '>>> Failed initializing app analytics tracking libs',
          err
        )
      })
  }
}

function _getUserData() {
  const state = store().getState()
  const { user } = state
  return [
    {
      is_authenticated: !!(user && user.logged_in),
      user_id: user && user.user_id,
      contact_id: user && user.contactId,
    },
    user,
  ]
}

function onPageLoadHome() {
  const [userAnalyticsData] = _getUserData()

  _onPageLoad(
    Object.assign({}, pageData.home, userAnalyticsData),
    _get(analyticsHelper.constants, 'pages.home')
  )

  const state = store().getState()
  const { user } = state

  const trackVars = {}
  if (user.contactId && user.sub) {
    trackVars.contactId = user.contactId
    trackVars.sub = user.sub
    trackVars['tenx.cre.md5_user_email'] = user.email ? md5(user.email) : ''
  }

  gtm.trackEvent('page_view', {
    page_data: {},
    user_data: {
      user_id: `${userAnalyticsData.user_id || ''}`,
      contact_id: `${userAnalyticsData.contact_id || ''}`,
      user_login_status: userAnalyticsData.is_authenticated,
    },
  })
  gtm.trackEvent('Homepage Property Viewed', trackVars)
}

function onPageLoadLogin() {
  const [userAnalyticsData] = _getUserData()

  _onPageLoad(
    Object.assign({}, pageData.login, userAnalyticsData),
    _get(analyticsHelper.constants, 'pages.login')
  )
}

function onPageLoadCreateAccount() {
  const [userAnalyticsData] = _getUserData()

  _onPageLoad(
    Object.assign({}, pageData.createAccount, userAnalyticsData),
    _get(analyticsHelper.constants, 'pages.signup')
  )
}

function onPageLoadForgotPassword() {
  const [userAnalyticsData] = _getUserData()

  _onPageLoad(
    Object.assign({}, pageData.forgotPassword, userAnalyticsData),
    _get(analyticsHelper.constants, 'pages.forgotPassword')
  )
}

function onPageLoadChangePassword() {
  const [userAnalyticsData] = _getUserData()

  _onPageLoad(
    Object.assign({}, pageData.changePassword, userAnalyticsData),
    _get(analyticsHelper.constants, 'pages.resetPassword')
  )
}

function onPageLoadAccountConfirmed() {
  const [userAnalyticsData] = _getUserData()

  _onPageLoad(
    Object.assign({}, pageData.confirmAccount, userAnalyticsData),
    _get(analyticsHelper.constants, 'pages.confirmation')
  )
}

function onPageLoadUserInvite() {
  const [userAnalyticsData] = _getUserData()

  _onPageLoad(
    Object.assign({}, pageData.userInvite, userAnalyticsData),
    _get(analyticsHelper.constants, 'pages.userInvite')
  )
}

function onPageLoadResendConfirmEmail() {
  const [userAnalyticsData] = _getUserData()

  _onPageLoad(
    Object.assign({}, pageData.resendConfirmEmail, userAnalyticsData),
    _get(analyticsHelper.constants, 'pages.resendConfirmEmail')
  )
}

function onSignIn(data) {
  const [userData] = _getUserData()

  // GTM events

  gtm.trackEvent('User Sign In', userData)
}

function onForgotPassword() {
  const [userAnalyticsData] = _getUserData()
  const userId = userAnalyticsData.user_id

  // GTM events
  gtm.trackEvent('Password Reset Requested', { user_id: userId })
}

function onResetPassword() {
  const [userAnalyticsData] = _getUserData()
  const userId = userAnalyticsData.user_id

  // GTM events

  gtm.trackEvent('ResetPassword', { user_id: userId })
}

function onCreateAccount(formValues) {
  // GTM events

  gtm.trackEvent('Account Created', {
    user_id: formValues.user_id,
  })
}

function onAccountConfirmed(userId) {
  // GTM events

  gtm.trackEvent('Account Confirmed', {
    user_id: userId,
  })
}

export default {
  // New Functions
  onPageLoadHome,
  onPageLoadLogin,
  onPageLoadCreateAccount,
  onPageLoadForgotPassword,
  onPageLoadChangePassword,
  onPageLoadAccountConfirmed,
  onPageLoadUserInvite,
  onPageLoadResendConfirmEmail,
  onSignIn,
  onForgotPassword,
  onResetPassword,
  onCreateAccount,
  onAccountConfirmed,
}
