import { helpers } from '@ten-x/core-utils'

import { ASSETS_REQUESTED, ASSETS_RECEIVED } from '../constants/actionTypes'
import store from '../store.js'
import searchService from '../services/search'

let latestRequest = 0

const searchFn = async (propertyType) => {
  if (!helpers.environments.canUseDom()) {
    // NOTE: SHOULD NOT BE RUNNING THIS ANYWAY
    // no caching on the server (at least not in the react app directly)
    console.error('AVOID RUNNING ASYNC ACTIONS ON THE SERVER!!!')
    return
  }
  // in the user's browser, be sure to only use the latest request
  const requestTime = Date.now()

  try {
    const result = await searchService.get()
    if (requestTime > latestRequest) {
      latestRequest = requestTime
      store().dispatch(receive(result, propertyType))
    }
    return true
  } catch (e) {
    console.error(e)
  }
}

// get assets immediately
const request = () => {
  const propertyType = 'all'
  searchFn(propertyType)

  return {
    type: ASSETS_REQUESTED,
    meta: { propertyType },
  }
}
// when we get the assets, put them in here
const receive = (result, propertyType) => {
  return {
    type: ASSETS_RECEIVED,
    result: result,
    meta: { propertyType },
  }
}

export { request, receive }
