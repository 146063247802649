/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import configment from '@ten-x/configment'
import { Typography } from '@ten-x/fe-lib-ui2020'

import MSG from '../../constants/messages'

const { Title, Paragraph } = Typography

const Failure = (props) => {
  const forgotPasswordURL = `${configment.get(
    'LIB_AUTH_PATH_PREFIX'
  )}/auth/forgot-password`
  const { loginBackToURL } = props

  return (
    <Fragment>
      <AlreadyConfirmedTitle
        color="black"
        data-elm-id="confirm-account-error-title"
      >
        {MSG.ACCOUNT_ALREADY_CONFIRMED_TITLE}
      </AlreadyConfirmedTitle>
      <Paragraph color="black" data-elm-id="confirm-account-error-message">
        {MSG.ACCOUNT_ALREADY_CONFIRMED_BODY} {MSG.DO_YOU_WANT_TO}{' '}
        <a href={loginBackToURL}>{MSG.LOG_IN_LOW}</a> {MSG.OR}{' '}
        <a href={forgotPasswordURL}>{MSG.RECOVER_PASSWORD_Q}</a>
      </Paragraph>
    </Fragment>
  )
}

Failure.propTypes = {
  loginBackToURL: PropTypes.string.isRequired,
}

const AlreadyConfirmedTitle = styled(Title)`
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`

export default Failure
