import authProvider from '../data_providers/auth'
import userProvider from '../data_providers/user'
import ACTION_TYPES from '../constants/actionTypes'

const UserActions = {
  init: function () {
    return {
      types: [
        ACTION_TYPES.USER_INIT_START,
        ACTION_TYPES.USER_INIT_SUCCESS,
        ACTION_TYPES.USER_INIT_FAIL,
      ],
      promise: userProvider.get({}),
    }
  },

  logout: function () {
    return {
      types: [
        ACTION_TYPES.AUTH_LOGOUT_START,
        ACTION_TYPES.AUTH_LOGOUT_SUCCESS,
        ACTION_TYPES.AUTH_LOGOUT_FAIL,
      ],
      promise: authProvider.logout({}),
    }
  },

  resendConfirmEmail: function (email) {
    return {
      types: [
        ACTION_TYPES.RESEND_CONFIRM_EMAIL_START,
        ACTION_TYPES.RESEND_CONFIRM_EMAIL_SUCCESS,
        ACTION_TYPES.RESEND_CONFIRM_EMAIL_FAIL,
      ],
      promise: authProvider.resendConfirmEmail({ email }),
    }
  },

  confirmUser: function (args) {
    return {
      types: [
        ACTION_TYPES.CONFIRM_USER_START,
        ACTION_TYPES.CONFIRM_USER_SUCCESS,
        ACTION_TYPES.CONFIRM_USER_FAIL,
      ],
      promise: authProvider.confirmUser({ args }),
    }
  },
  getSavedAssets: function () {
    return {
      types: [
        ACTION_TYPES.SAVED_ASSETS_INIT,
        ACTION_TYPES.SAVED_ASSETS_SUCCESS,
        ACTION_TYPES.SAVED_ASSETS_FAIL,
      ],
      promise: userProvider.getSavedAssets(),
    }
  },
  updateSavedAssetList: function (asset) {
    return {
      type: ACTION_TYPES.UPDATE_SAVED_ASSETS,
      payload: asset,
    }
  },
}

export default UserActions
