export default [
  {
    name: 'Search by Property Type',
    key: 'srp-property-type',
    pages: [
      { path: '/search/', priority: 0.8, name: 'All' },
      {
        path: '/search/office_pt/',
        priority: 0.7,
        name: 'Office',
      },
      {
        path: '/search/retail_pt/',
        priority: 0.7,
        name: 'Retail',
      },
      {
        path: '/search/multi-family_pt/',
        priority: 0.7,
        name: 'Multifamily',
      },
      {
        path: '/search/hotel_pt/',
        priority: 0.7,
        name: 'Hotel',
      },
      {
        path: '/search/industrial_pt/',
        priority: 0.7,
        name: 'Industrial',
      },
      {
        path: '/search/self-storage_pt/',
        priority: 0.7,
        name: 'Self-Storage',
      },
      {
        path: '/search/land_pt/',
        priority: 0.7,
        name: 'Land',
      },
      {
        path: '/search/special-purpose_pt/',
        priority: 0.7,
        name: 'Special Purpose',
      },
    ],
  },
  {
    name: 'Search by filter',
    key: 'srp-property-filter',
    pages: [
      { path: '/search/nl_ic/', priority: 0.7 },
      { path: '/search/y_bc/', priority: 0.7 },
      { path: '/search/y_fa/', priority: 0.7 },
      { path: '/search/property_group/', priority: 0.7 },
      { path: '/search/notes_group/', priority: 0.7 },
    ],
  },
  {
    name: 'Search by Property State',
    key: 'srp-states',
    pages: [
      { path: '/search/al/', priority: 0.7, name: 'Alabama' },
      { path: '/search/ak/', priority: 0.7, name: 'Alaska' },
      { path: '/search/az/', priority: 0.7, name: 'Arizona' },
      {
        path: '/search/ca/',
        priority: 0.7,
        name: 'California',
      },
      { path: '/search/co/', priority: 0.7, name: 'Colorado' },
      {
        path: '/search/ct/',
        priority: 0.7,
        name: 'Connecticut',
      },
      {
        path: '/search/de/',
        priority: 0.7,
        name: 'Delaware',
      },
      { path: '/search/fl/', priority: 0.7, name: 'Florida' },
      { path: '/search/ga/', priority: 0.7, name: 'Georgia' },
      { path: '/search/hi/', priority: 0.7, name: 'Hawaii' },
      { path: '/search/id/', priority: 0.7, name: 'Idaho' },
      { path: '/search/il/', priority: 0.7, name: 'Illinois' },
      { path: '/search/in/', priority: 0.7, name: 'Indiana' },
      { path: '/search/ia/', priority: 0.7, name: 'Iowa' },
      { path: '/search/ks/', priority: 0.7, name: 'Kansas' },
      { path: '/search/ky/', priority: 0.7, name: 'Kentucky' },
      {
        path: '/search/la/',
        priority: 0.7,
        name: 'Louisiana',
      },
      { path: '/search/me/', priority: 0.7, name: 'Maine' },
      { path: '/search/md/', priority: 0.7, name: 'Maryland' },
      {
        path: '/search/ma/',
        priority: 0.7,
        name: 'Massachusetts',
      },
      { path: '/search/mi/', priority: 0.7, name: 'Michigan' },
      {
        path: '/search/mn/',
        priority: 0.7,
        name: 'Minnesota',
      },
      {
        path: '/search/ms/',
        priority: 0.7,
        name: 'Mississippi',
      },
      { path: '/search/mo/', priority: 0.7, name: 'Missouri' },
      { path: '/search/mt/', priority: 0.7, name: 'Montana' },
      { path: '/search/ne/', priority: 0.7, name: 'Nebraska' },
      { path: '/search/nv/', priority: 0.7, name: 'Nevada' },
      {
        path: '/search/nh/',
        priority: 0.7,
        name: 'New Hampshire',
      },
      {
        path: '/search/nj/',
        priority: 0.7,
        name: 'New Jersey',
      },
      {
        path: '/search/nm/',
        priority: 0.7,
        name: 'New Mexico',
      },
      { path: '/search/ny/', priority: 0.7, name: 'New York' },
      {
        path: '/search/nc/',
        priority: 0.7,
        name: 'North Carolina',
      },
      {
        path: '/search/nd/',
        priority: 0.7,
        name: 'North Dakota',
      },
      { path: '/search/oh/', priority: 0.7, name: 'Ohio' },
      { path: '/search/ok/', priority: 0.7, name: 'Oklahoma' },
      { path: '/search/or/', priority: 0.7, name: 'Oregon' },
      {
        path: '/search/pa/',
        priority: 0.7,
        name: 'Pennsylvania',
      },
      {
        path: '/search/ri/',
        priority: 0.7,
        name: 'Rhode Island',
      },
      {
        path: '/search/sc/',
        priority: 0.7,
        name: 'South Carolina',
      },
      {
        path: '/search/sd/',
        priority: 0.7,
        name: 'South Dakota',
      },
      {
        path: '/search/tn/',
        priority: 0.7,
        name: 'Tennessee',
      },
      { path: '/search/tx/', priority: 0.7, name: 'Texas' },
      { path: '/search/ut/', priority: 0.7, name: 'Utah' },
      { path: '/search/vt/', priority: 0.7, name: 'Vermont' },
      {
        path: '/search/wa/',
        priority: 0.7,
        name: 'Washington',
      },
      {
        path: '/search/wv/',
        priority: 0.7,
        name: 'West Virginia',
      },
      {
        path: '/search/wi/',
        priority: 0.7,
        name: 'Wisconsin',
      },
      { path: '/search/wy/', priority: 0.7, name: 'Wyoming' },
    ],
  },
  {
    name: 'Resources',
    key: 'resources',
    pages: [
      { path: '/company/legal/licensing/', priority: 0.6 },
      { path: '/company/legal/privacy/', priority: 0.6 },
      { path: '/company/legal/terms/', priority: 0.6 },
      { path: '/company/legal/participation-terms/', priority: 0.6 },
      {
        path: '/company/legal/participation-terms/archives/',
        priority: 0.5,
      },
      { path: '/company/legal/privacy/archives/', priority: 0.5 },
      { path: '/company/legal/terms/archives/', priority: 0.5 },
      { path: '/company/legal/txmast04222021/', priority: 0.6 },
      { path: '/company/legal/txmast02102017/', priority: 0.5 },
      { path: '/company/legal/txmast01282021/', priority: 0.5 },
      { path: '/company/legal/txmast01012020/', priority: 0.5 },
      { path: '/company/legal/txmast01012019/', priority: 0.5 },
      { path: '/company/legal/txmast07012018/', priority: 0.5 },
      { path: '/company/legal/txmast02202018/', priority: 0.5 },
      { path: '/company/legal/txmast08232017/', priority: 0.5 },
      { path: '/company/legal/mobile-terms/', priority: 0.6 },
      { path: '/company/resources/about-ten-x/', priority: 0.7 },
      { path: '/lookbook/', priority: 0.7 },
      { path: '/company/commercial/help/', priority: 0.7 },
    ],
  },
  {
    name: 'Landing pages',
    key: 'landing',
    pages: [
      { path: '/', priority: 1 },
      { path: '/broker-commercial-real-estate/', priority: 0.8 },
      { path: '/sell-commercial-real-estate/', priority: 0.8 },
      { path: '/buy-commercial-real-estate/', priority: 0.8 },
      { path: '/managed-bid/', priority: 0.7 },
      { path: '/dashboard/', priority: 0.7 },
      { path: '/faster', priority: 0.8 },
      { path: '/seeit', priority: 0.8 },
    ],
  },
  {
    name: 'Other',
    key: 'other',
    pages: [
      { path: '/login', priority: 0.8 },
      { path: '/signup', priority: 0.8 },
      { path: '/company/press', priority: 0.7, enabled: false },
      { path: '/me/search/', priority: 0.6 },
    ],
  },
]
