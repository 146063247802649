/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import styled from '@emotion/styled'
import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router'
import { Row, Col, notification } from 'antd'
import { CAEmailChecker, Typography } from '@ten-x/fe-lib-ui2020'
import configment from '@ten-x/configment'

import analytics from '../../services/analytics'
import MSG from '../../constants/messages'

const { Title } = Typography

const Login = ({ location, history }) => {
  useEffect(() => {
    analytics.onPageLoadLogin()
  }, [])

  async function onEmailCheckComplete(response) {
    const backTo = getBackToUrl(location)

    if (response.exists.ca) {
      try {
        window.location = `${configment.get(
          'LIB_AUTH_PATH_PREFIX'
        )}/auth/login?login_hint=${response.email}${
          backTo ? `&back=${encodeURIComponent(backTo)}` : ''
        }`
      } catch (error) {
        console.error('Failed redirecting user to CA: ', error)
      }
    } else {
      if (response.exists.uaa) {
        history.push('/signup?showLegacyWarning=true')
        return
      }
      history.push('/signup')
    }
  }

  function onEmailCheckFail() {
    notification.error({
      message: 'Error processing your request!',
      description:
        "We couln't verify if you are alredy a user, please try again later.",
    })
  }

  return (
    <Fragment>
      <Helmet title={MSG.LOG_IN} />
      <ContentContainer>
        <ResponsiveCol span={20} md={12} xl={9} xxl={7}>
          <LoginTitle color="black">Login/Sign up</LoginTitle>
          <CAEmailChecker
            onSuccess={onEmailCheckComplete}
            onFail={onEmailCheckFail}
          />
        </ResponsiveCol>
      </ContentContainer>
    </Fragment>
  )
}

Login.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.object,
  }).isRequired,
  history: PropTypes.object,
}

export default withRouter(Login)

const getBackToUrl = (location) => {
  const qs = new URLSearchParams(location.search)
  const backTo = qs.get('back')

  if (backTo) {
    return backTo
  }

  const referrer = document.referrer
  if (referrer !== '') {
    return referrer
  }

  return ''
}

const ContentContainer = styled(Row)`
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
`

const ResponsiveCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const LoginTitle = styled(Title)`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  text-transform: uppercase;
`
