function api2fe(rawData) {
  const savedAssetResponse = {
    total: rawData.total,
    saved_assets: [],
  }
  if (rawData.saved_properties) {
    for (const asset of rawData.saved_properties) {
      savedAssetResponse.saved_assets.push({
        enterpriseListingId: asset.enterpriseListingId,
      })
    }
  }

  return savedAssetResponse
}

module.exports.api2fe = api2fe
