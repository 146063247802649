const formatPhoneNumber = (value) => {
  var result = ''
  if (value && (typeof value === 'string' || typeof value === 'number')) {
    if (typeof value === 'number') {
      value = value.toString()
    }
    value = value.replace(/[-)( ]/g, '')
    result =
      value.length === 10
        ? `(${value.substr(0, 3)}) ${value.substr(3, 3)}-${value.substr(6)}`
        : `${value.substr(0, 1)} (${value.substr(1, 3)}) ${value.substr(
            4,
            3
          )}-${value.substr(7)}`
  }
  return result
}

export function addressToMultiLineString(obj) {
  const line1 = [obj.street_number, obj.street_name, obj.street_unit]
    .filter((v) => !!v)
    .join(' ')
  let line2 = [obj.municipality, obj.country_subdivision]
    .filter((v) => !!v)
    .join(', ')
  if (obj.postal_code) {
    line2 += ' ' + obj.postal_code
  }
  return `${line1}\n${line2}`
}

export function addressToLine1(obj) {
  const line1 = [obj.street_number, obj.street_name, obj.street_unit]
    .filter((v) => !!v)
    .join(' ')
  return line1
}

export function addressToLine2(obj) {
  let line2 = [obj.municipality, obj.country_subdivision]
    .filter((v) => !!v)
    .join(', ')
  if (obj.postal_code) {
    line2 += ' ' + obj.postal_code
  }
  return line2
}

export default {
  formatPhoneNumber,
  addressToMultiLineString,
}
export { formatPhoneNumber }
