import { ASSETS_REQUESTED, ASSETS_RECEIVED } from '../constants/actionTypes'

// const initialState = {}

const assetsReducer = function (state, action) {
  if (typeof state === 'undefined') {
    return { all: { list: [], loading: true } }
  }
  const propertyType =
    (action && action.meta && action.meta.propertyType) || 'all'
  // if (action && action.meta && action.meta.propertyType) {
  //   propertyType = action.meta.propertyType
  //   newState = { [action.meta.propertyType]: {} }
  // } else {
  //   newState = { all: {} }
  // }
  const newState = { [propertyType]: {} }

  Object.assign(newState, state)

  if (action.type === ASSETS_RECEIVED) {
    newState[propertyType].list = action.result.assets.slice()
    newState[propertyType].initialized = true
    newState[propertyType].loading = false
    newState[propertyType].offset = action.result.offset
    newState[propertyType].errors = action.result.errors
    newState[propertyType].total = action.result.total
    newState[propertyType].recommendedListingsForYou =
      action.result.recommendedListingsForYou

    return newState
  }

  if (action.type === ASSETS_REQUESTED) {
    newState[propertyType].loading = true
    return newState
  }

  return state
}

export default assetsReducer
// export { initialState }
