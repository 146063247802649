const constants = {
  events: {
    pageLoad: 'Page Visited',
    linkClicked: 'Link Clicked',
    accountCreated: 'Account Created',
    userLoggedIn: 'User Sign In',
    // @TODO we need to send this when user logs out
    user_logged_out: 'User Logged Out',
    accountConfirmed: 'Account Confirmed',
    saveAsset: 'Save Asset',
    accountCreationStarted: 'Account Creation Started',
  },
  pages: {
    home: 'Home',
    signup: 'Sign Up',
    login: 'Log In',
    confirmation: 'Account Confirmation',
    userInvite: 'User Invite',
    resendConfirmEmail: 'Resend Confirm Email',
  },
}

module.exports = {
  constants,
}
