import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { connect } from 'react-redux'
import configment from '@ten-x/configment'

import App from './app'
import Home from './containers/home'
import Layout from './containers/layout'
import Login from './containers/login'
import Signup from './containers/signup'
import ResendConfirmEmail from './containers/resendConfirmEmail'
import UserActivation from './containers/userActivation'
import Activate from './containers/activation'
import ConfirmEmail from './containers/confirmEmail'
import ConfirmBroker from './containers/confirmBroker'
import PrivateRoute from './containers/privateRoute'
import SDKTest from './containers/SDKTest'
import ErrorPage from './containers/errorPage'
import NotFound from './containers/notFound'
import types from './constants/prop_types'
import Sitemap from './containers/sitemap'

Routes.propTypes = {
  user: types.user.isRequired,
}
const isProd = () => configment.get('ENV') === 'prod'

function Routes(props) {
  const { user } = props

  // this is a very simple higher order return a redirect component
  // if the user is not logged in, we use this to redirect the already
  // logged in user to the main website.
  function redirectIfUserLoggedIn(component) {
    return user.logged_in ? () => <Redirect to="/commercial" /> : component
  }

  return (
    <App>
      <Switch>
        <Route exact path={['/', '/commercial']} component={Home} />
        {/* The following route is only meant to be accesible outside of PROD evironemtn to test SDK functionalities */}
        {!isProd() && <Route path="/test-sdk" component={SDKTest} />}
        {/* The following route is only meant to be opened inside an iframe created by the Iframe SDK library on an external site */}
        <Route exact path="/commercial/error" component={ErrorPage} />
        <Route
          path={[
            '/login',
            '/commercial/login',
            '/signup',
            '/commercial/signup',
            '/user/resend-confirm-email',
            '/commercial/user/resend-confirm-email',
            '/user/confirm-account',
            '/commercial/user/confirm-account',
            '/user/invite/:token',
            '/commercial/user/invite/:token',
            '/user/confirm-email',
            '/commercial/user/confirm-email',
            '/user/confirm-broker/:brokerRegistrationId',
            '/commercial/user/confirm-broker/:brokerRegistrationId',
            '/sitemap',
          ]}
        >
          <Layout>
            <Switch>
              <Route path={['/login', '/commercial/login']} component={Login} />
              <Route path={['/sitemap']} component={Sitemap} />
              <Route
                path={['/signup', '/commercial/signup']}
                component={redirectIfUserLoggedIn(Signup)}
              />
              <Route
                path={[
                  '/user/resend-confirm-email',
                  '/commercial/user/resend-confirm-email',
                ]}
                component={redirectIfUserLoggedIn(ResendConfirmEmail)}
              />
              <Route
                path={[
                  '/user/confirm-account',
                  '/commercial/user/confirm-account',
                ]}
                component={redirectIfUserLoggedIn(UserActivation)}
              />
              <Route
                path={['/user/invite/:token', '/commercial/user/invite/:token']}
                component={redirectIfUserLoggedIn(Activate)}
              />
              <Route
                path={['/user/confirm-email', '/commercial/user/confirm-email']}
                component={redirectIfUserLoggedIn(ConfirmEmail)}
              />
              <PrivateRoute
                path={[
                  '/user/confirm-broker/:brokerRegistrationId',
                  '/commercial/user/confirm-broker/:brokerRegistrationId',
                ]}
                component={ConfirmBroker}
                requiredFlags={[]}
              />
            </Switch>
          </Layout>
        </Route>
        <Route component={NotFound} />
      </Switch>
    </App>
  )
}

function mapStateToProps(store) {
  return {
    user: store.user,
  }
}

export default connect(mapStateToProps)(Routes)
