import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import ClassNames from 'classnames/bind'

import cls from './styles.scss'

const cx = ClassNames.bind(cls)

const FactMetric = ({ metricValue, body }) => {
  return (
    <div className={cx('box')}>
      <span className={cx('metric-number')}>{metricValue}</span>
      {body}
    </div>
  )
}
FactMetric.propTypes = {
  metricValue: PropTypes.string,
  body: PropTypes.object,
}

const PoweringTransactionsSection = () => {
  return (
    <PoweringTransactionsContainer
      className={cx('home-section-5')}
      data-elm-id="powering-transactions-section"
    >
      <div className={cx('container')}>
        <Row>
          <Col xl={{ span: 30, offset: 1 }} lg={24}>
            <h2 className={cx('section-title')}>
              The leading platform for trading commercial real estate.
            </h2>
            <p className={cx('section-desc')}>
              The boundless opportunity of the nation’s best CRE assets paired
              with the simplicity & security of an online transaction.
            </p>
          </Col>
        </Row>
        <Row
          gutter={80}
          className={cx('metrics-row')}
          data-elm-id="metrics-row"
        >
          <Col lg={6} md={12} sm={12} xs={24}>
            <FactMetric
              metricValue="3,500"
              body={
                <p className={cx('metric-description')}>
                  UNIQUE PAGE VIEWS PER PROPERTY ON AVERAGE
                </p>
              }
            />
          </Col>
          <Col lg={6} md={12} sm={12} xs={24}>
            <FactMetric
              metricValue="500,000+"
              body={
                <p className={cx('metric-description')}>
                  UNIQUE ACTIVE INVESTORS
                </p>
              }
            />
          </Col>
          <Col lg={6} md={12} sm={12} xs={24}>
            <FactMetric
              metricValue="96%"
              body={
                <p className={cx('metric-description')}>
                  OF PROPERTIES SOLD in 60 Days OR LESS
                </p>
              }
            />
          </Col>
          <Col lg={6} md={12} sm={12} xs={24}>
            <FactMetric
              metricValue="52%"
              body={
                <p className={cx('metric-description')}>
                  OF PROPERTIES SOLD TO OUT-OF-STATE BUYERS
                </p>
              }
            />
          </Col>
        </Row>
      </div>
    </PoweringTransactionsContainer>
  )
}

export const PoweringTransactionsContainer = styled.div`
  padding: 40px 0;
  background-color: #000;

  background-image: url(https://cre-prd-img.imgix.net/site%2Fstatic%2Fgradient-XVector-Right.v2.svg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 1150px;
  min-height: 760px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    min-height: unset;
  }
`

export default PoweringTransactionsSection
