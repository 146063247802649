/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import React from 'react'
import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Button } from '@ten-x/fe-lib-ui2020'
import ClassNames from 'classnames/bind'

import types from '../../../../constants/prop_types'

import cls from './styles.scss'

const cx = ClassNames.bind(cls)

const AccelerateCRESection = (props) => {
  const { user, urlList } = props

  return (
    <AccelerateCRESectionContainer
      className={cx('cta-section')}
      data-elm-id="accelerate-cre-section"
    >
      <AccelerateCRERowContainer>
        <AccelerateCRERow>
          <Col sm={24} md={12}>
            <div className={cx('section-info')}>
              <TitleContainer color="black">
                <span className={cx('title')}>
                  Why just sell it <br />
                  when you can <br />
                </span>
                <br className={cx('lg-hidden')} />
                <TitleTenX>Ten-X it.</TitleTenX>
              </TitleContainer>
              <AccelerateButtonRowContainer>
                <AccelerateButton
                  color="blue"
                  size="default"
                  href={urlList.search || '/search'}
                  data-elm-id="accelerate-cre-search"
                >
                  START BROWSING
                </AccelerateButton>
                {user && user.logged_in ? null : (
                  <AccelerateButton
                    type="primary"
                    size="default"
                    onClick={props.showSignupModal}
                    data-elm-id="accelerate-cre-signup"
                  >
                    GET PROPERTY ALERTS
                  </AccelerateButton>
                )}
              </AccelerateButtonRowContainer>
            </div>
          </Col>
        </AccelerateCRERow>
      </AccelerateCRERowContainer>
    </AccelerateCRESectionContainer>
  )
}

AccelerateCRESection.propTypes = {
  user: types.user,
  showSignupModal: PropTypes.func,
  urlList: PropTypes.object.isRequired,
}

export const AccelerateCRESectionContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.grayLight};
  background-image: url(https://cre-prd-img.imgix.net/site%2Fstatic%2Fwhite-XVector-Left.v2.svg);
  background-repeat: no-repeat;
  background-size: 1136px;
  background-position: left;
  min-height: 760px;
  position: relative;

  &::before {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;

    background-image: url(https://cre-prd-img.imgix.net/site%2Fstatic%2FTXBrand_Creative_Territories_Deck02.v1.png?auto=format,
       compress);
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
      width: 0;
      height: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    padding: 25px 0;
    min-height: unset;
    padding-left: 0;
  }
`

const AccelerateCRERowContainer = styled.div`
  max-width: 1440px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
`

export const AccelerateCRERow = styled(Row)`
  padding-left: 50%;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    padding-left: 0;
    display: flex;
    justify-content: center;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.xxl * 2}px) {
    padding-left: 25%;
  }

  .ant-col-md-12 {
    width: 500px;
    padding-top: 150px;

    @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
      padding-top: 120px;
    }

    @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
      width: 100%;
      padding-top: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    width: 100%;
    padding-left: 0;
  }
`

export const TitleContainer = styled.h2`
  display: flex;
  flex-direction: column;
  align-items: left;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    align-items: center;
  }

  span.title {
    font-size: 64px;
    line-height: 75px;
    color: black;
    font-weight: ${({ theme }) => theme.font.weight.normal};

    @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
      font-size: 40px;
      line-height: 50px;
    }

    @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
      font-size: 36px;
      line-height: 44px;
    }
  }
`

export const TitleTenX = styled.div`
  font-size: 120px;
  line-height: 145px;
  font-weight: ${({ theme }) => theme.font.weight.normal};
  background: ${({ theme }) => theme.palette.costarGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 64px;
    line-height: 75px;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 36px;
    line-height: 45px;
  }
`

export const AccelerateButton = styled(Button)`
  margin-top: 48px;
  margin-right: 30px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    margin-left: auto;
    margin-right: auto;
  }
`

export const AccelerateButtonRowContainer = styled(Row)`
  display: flex;
  flex-direction: row;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    flex-direction: column;
  }
`

export default AccelerateCRESection
