/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { styles, Typography } from '@ten-x/fe-lib-ui2020'
import configment from '@ten-x/configment'

import MSG from '../../constants/messages'
import { formatPhoneNumber } from '../../utils/format'

const { Title, Paragraph } = Typography
const { styled } = styles

const ActivateSuccessSplashScreen = (props) => {
  const dashboardURL = `${configment.get('URL_CRE_BASE_PREFIX')}/login`
  const formatedPhoneNumber = formatPhoneNumber(MSG.TENX_PHONE_NUMBER)

  return (
    <Fragment>
      <ConfirmTitle color="black">{MSG.ACTIVATION_COMPLETED}</ConfirmTitle>
      <ConfirmTitle level={3} color="black">
        {MSG.WELCOME_TO_TENX}
      </ConfirmTitle>
      <Paragraph color="black">
        {MSG.WELCOME_EMAIL_SENT} {props.email}
      </Paragraph>
      <DividerParagraph color="black">
        {MSG.ACTIVATE_LOGIN}{' '}
        <Link href={dashboardURL} target="_self">
          {MSG.GOTO_DASHBOARD_TEXT}
        </Link>
      </DividerParagraph>
      <ConfirmTitle level={3} color="black">
        {MSG.HELP_MSG}
      </ConfirmTitle>
      <Paragraph color="black">
        {MSG.ACTIVATE_CALL_US_IF_NO_WELCOME_EMAIL}{' '}
        <Link href={`tel:+${MSG.TENX_PHONE_NUMBER}`}>
          {formatedPhoneNumber}
        </Link>
        , {MSG.CALL_HOURS}
      </Paragraph>
    </Fragment>
  )
}

ActivateSuccessSplashScreen.propTypes = {
  email: PropTypes.string.isRequired,
}

const ConfirmTitle = styled(Title)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(5)};
`
)

const DividerParagraph = styled(Paragraph)(({ theme }) => {
  const { spacing } = theme
  return `
    padding-bottom: ${spacing(5)};
    border-bottom: 2px solid ${theme.palette.whiteDark};
    margin-bottom: ${spacing(2)};
  `
})

const Link = styled.a`
  color: ${({ theme }) => theme.colors.link.primary};
  text-decoration: underline;
  font-weight: ${({ theme }) => theme.font.weight.normal};
`

export default ActivateSuccessSplashScreen
