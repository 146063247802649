/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { notification, Row, Col } from 'antd'
import styled from '@emotion/styled'
import configment from '@ten-x/configment'
import _get from 'lodash/get'

import ProcessingOverlay from '../processingOverlay'
import MSG from '../../constants/messages'
import AuthProvider from '../../data_providers/auth'
import analytics from '../../services/analytics'

import Failure from './Failure'
import Success from './Success'

const MANAGED_ERRORS = ['US112', 'user is already confirmed']

const ConfirmAccount = (props) => {
  const [isConfirmingUser, setIsConfirmingUser] = useState(true)
  const [confirmSuccess, setConfirmSuccess] = useState(false)
  const [alreadyConfirmed, setAlreadyConfirmed] = useState(false)

  const { URLBasedParams, backToURL } = props

  useEffect(() => {
    let unmounted = false

    // Declare async function and control side effects with mounted variable
    const confirmUserEmail = async () => {
      try {
        const res = await AuthProvider.confirmUser({
          args: URLBasedParams,
        })

        if (unmounted) return

        const userId = (res && res.value && res.value.userId) || undefined
        analytics.onAccountConfirmed(userId)

        setConfirmSuccess(true)
      } catch (err) {
        if (unmounted) return

        const error = _get(err, 'status.errors[0].code', '') || err.message
        if (MANAGED_ERRORS.includes(error)) {
          setAlreadyConfirmed(true)
        } else {
          notification.error({
            message: MSG.CONFIRM_USER_ERROR_TITLE,
            description: MSG.CONFIRM_USER_ERROR_BODY,
          })
        }
      }
      setIsConfirmingUser(false)
    }

    confirmUserEmail()

    return () => (unmounted = true)
  }, [])

  function getLoginBackToURL() {
    const config = configment.get()
    let newLocation = `${config.URL_CRE_BASE_PREFIX}/login`

    if (backToURL) {
      newLocation = `${newLocation}?back=${backToURL}`
    }
    return newLocation
  }

  const loginBackToURL = getLoginBackToURL()

  return (
    <ContentContainer data-elm-id="confirm-account-container">
      {isConfirmingUser ? (
        <ProcessingOverlay />
      ) : (
        <ResponsiveCol span={20} md={12} lg={10} xl={8} xxl={6}>
          {confirmSuccess && <Success loginBackToURL={loginBackToURL} />}
          {alreadyConfirmed && <Failure loginBackToURL={loginBackToURL} />}
        </ResponsiveCol>
      )}
    </ContentContainer>
  )
}

ConfirmAccount.propTypes = {
  URLBasedParams: PropTypes.object.isRequired,
  backToURL: PropTypes.string.isRequired,
}

export const ContentContainer = styled(Row)`
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
`

export const ResponsiveCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export default ConfirmAccount
