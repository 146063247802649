import React, { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import ClassNames from 'classnames/bind'

import cls from './styles.scss'

const cx = ClassNames.bind(cls)

class TestimonialsSection extends Component {
  static propTypes = {
    carouselAssets: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        url: PropTypes.string,
      })
    ).isRequired,
    title: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.carousel = React.createRef()
  }

  render() {
    return (
      <TestimonialsContainer
        data-elm-id="testimonials-container"
        className={cx('testimonials-container')}
      >
        <TestimonialsSectionContainer>
          <TestimonialsTitle>{this.props.title}</TestimonialsTitle>
          <TestimonialsImages>
            {this.props.carouselAssets.map((asset) => {
              return (
                <div key={asset.key} className={cx('carousel-slide')}>
                  <img
                    src={asset.url}
                    className={cx(`enterprise-image ${asset.key}`)}
                  />
                </div>
              )
            })}
          </TestimonialsImages>
        </TestimonialsSectionContainer>
      </TestimonialsContainer>
    )
  }
}

export const TestimonialsContainer = styled.h2`
  background-image: url(https://cre-prd-img.imgix.net/site%2Fstatic%2Fgray-XVector-Left.v2.svg);
  background-repeat: no-repeat;
  background-position: left;
  background-size: 1285px;
  min-height: 760px;
  font-weight: ${({ theme }) => theme.font.weight.normal};

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    min-height: unset;
  }
`

export const TestimonialsSectionContainer = styled.div`
  max-width: 1440px;
  margin: auto;
  padding: 0 30px;

  display: flex;
  flex-direction: row;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    padding-top: 40px;
    flex-direction: column;
    padding: 0;
  }
`

export const TestimonialsTitle = styled.div`
  font-size: 64px;
  line-height: 75px;
  background: ${({ theme }) => theme.palette.costarGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 60%;
  padding-left: 40px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    font-size: 40px;
    line-height: 50px;
    width: 50%;
    padding-right: 20px;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 36px;
    line-height: 45px;
    width: 100%;
  }
`

export const TestimonialsImages = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 10px;
  width: 40%;
  height: 100%;
  align-self: center;
  padding-left: 20px;

  div {
    height: 100px;

    img {
      max-height: 72px;
      max-width: 270px;

      &.cbre {
        position: relative;
        top: 20px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    width: 50%;
    padding-left: 0;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    grid-template-columns: 1fr;
    width: 100%;
    grid-gap: 20px 0;
    padding: 40px;

    div {
      height: 50px;

      img {
        max-height: 50px;
      }
    }
  }
`

export default TestimonialsSection
