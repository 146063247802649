import fetch from '@ten-x/core-utils/src/helpers/fetch'
import configment from '@ten-x/configment'

function getSystemMessage() {
  return fetch(
    `${configment.get('PLATFORM_API_GLS_URL')}/gls/v1/site-message`
  ).then((res) => res.body)
}

export default {
  getSystemMessage,
}
