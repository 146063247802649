import * as React from 'react'
import { hydrate } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
// import { datadogRum } from '@datadog/browser-rum'
// import configment from '@ten-x/configment'
import configment from '@ten-x/configment'

import '../common/scss/config.scss'
import Routes from '../common/routes.jsx'
import configureStore from '../common/store'
import ReactQueryClientProvider from '../common/containers/ReactQueryClientProvider'

// commenting out datadog rum code as BoB FE has recording needed for further PoC
// const appENV = configment.get('ENV')
// const datadogappID = '937a357d-1da4-4ee6-aa9b-957e9969e89c'
// const datadogclientToken = 'pubf0e144890b84c42e305d04e8616a9782'

// datadogRum.init({
//   applicationId: datadogappID,
//   clientToken: datadogclientToken,
//   site: 'datadoghq.com',
//   service: 'cre-home',
//   env: appENV,
//   // Specify a version number to identify the deployed version of your application in Datadog
//   // version: '1.0.0',
//   sessionSampleRate: 100,
//   sessionReplaySampleRate: 100,
//   trackUserInteractions: true,
//   trackResources: true,
//   trackLongTasks: true,
//   defaultPrivacyLevel: 'mask-user-input',
//   allowedTracingUrls: [/http(s?):\/\/www((-((dvm)|(ts(m|r))))?).ten-x.com\//],
// })

// datadogRum.startSessionReplayRecording()

export function clientRender(initialState, root) {
  const store = configureStore(initialState)

  // This will set the store for debugging purposes
  if (typeof window !== 'undefined') {
    window.debugStore = store
  }

  // The router does not use basename because we are doing some weird things
  // in the highlevel routing where we handle both / and /homepage.
  // This assumes that URL_BASE_PREFIX is set to be "/", if we ever change
  // that (which seems unlikily now) we might want to include
  // basename={configment.get("URL_BASE_PREFIX")} to BrowserRouter
  hydrate(
    <Provider store={store}>
      <BrowserRouter basename={configment.get('URL_BASE_PREFIX')}>
        <ReactQueryClientProvider>
          <Routes />
        </ReactQueryClientProvider>
      </BrowserRouter>
    </Provider>,
    root
  )
}
