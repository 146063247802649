const OPTIONS_DATA = [
  {
    key: 'brokers-option',
    elementId: 'home-static-brokers',
    track: 'brokers',
    heading: 'For Brokers',
    shortDescription: 'Succeed with built-in broker benefits',
    description:
      'Achieve a quick, lucrative close by tapping into full-service transaction resources at no cost to you. Leverage Ten-X’s end-to-end platform and spend more time building relationships.',
    buttonLabel: 'CLOSE DEALS FASTER',
    urlListPath: 'experience.brokers',
    defaultURL: 'https://commercial.ten-x.com/experience/brokers/',
  },
  {
    key: 'buyers-option',
    elementId: 'home-static-buyers',
    track: 'buyers',
    heading: 'For Buyers',
    shortDescription: 'Find new investment opportunities',
    description:
      'Ten-X takes the hassle, stress and uncertainty out of buying commercial real estate. As the leading end-to-end transaction platform, Ten-X offers buyers access to new opportunities and a simplified transaction.',
    buttonLabel: 'FIND YOUR INVESTMENT',
    urlListPath: 'experience.buyers',
    defaultURL: 'https://commercial.ten-x.com/experience/buyers/',
  },
  {
    key: 'sellers-option',
    elementId: 'home-static-sellers',
    track: 'sellers',
    heading: 'For Sellers',
    shortDescription: 'Sell your commercial property and maximize value',
    description:
      'Achieve a successful transaction by reaching the largest buying pool in the world. Put the Ten-X platform to work for you, ensure a successful sale, and let us help your broker facilitate a quick close on your terms.',
    buttonLabel: 'LEARN MORE',
    urlListPath: 'experience.sellers',
    defaultURL: 'https://commercial.ten-x.com/experience/sellers/',
  },
]

export { OPTIONS_DATA }
