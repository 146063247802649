import styled from '@emotion/styled'
import { Modal } from 'antd'
import { Button, PropertyTile, ArrowButton } from '@ten-x/fe-lib-ui2020'

export const Title = styled.h1`
  text-align: center;
  font-size: 20px;
`

export const InterestConfirmationModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-body {
      padding: 20px 40px;
    }
  }

  .slick-slide {
    text-align: center;
  }
`

export const PropertyWrapper = styled.div`
  padding: 0 30px;
  text-align: left;
`

export const BackgroundExtension = styled.div`
  background-color: ${({ theme }) => theme.palette.whiteDark};
  border-radius: 5px;
  padding: 0 20px;
  > div {
    height: auto;

    @media (max-width: ${({ theme }) => theme.screenBreakpoints.xs}px) {
      margin: 0;
      width: 100%;
    }
  }
`

export const StyledPropertyTile = styled(PropertyTile)`
  pointer-events: none !important;
  cursor: default !important;
`

export const Dots = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0 20%;
  margin: 20px 0;
`

export const Dot = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: lightGray;
  background-color: ${({ current }) => (current ? 'lightGray' : 'auto')};
  border-radius: 10px;
  width: 15px;
  height: 15px;
`

export const InterestButtons = styled.div`
  display: flex;
  margin: 0 30px;

  > button:first-child {
    margin-right: 10px;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.xs}px) {
    flex-direction: column;

    > button {
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
  }
`

export const InterestButton = styled(Button)`
  flex: 1;
  padding: 10px 0;
`

export const UndecidedButton = styled(Button)`
  margin-top: 10px;
  flex: 1;
`

export const LeftArrowButton = styled(ArrowButton)`
  position: absolute;
  top: 220px;
  left: 40px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.xs}px) {
    left: 20px;
  }
`

export const RightArrowButton = styled(ArrowButton)`
  position: absolute;
  top: 220px;
  right: 40px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.xs}px) {
    right: 20px;
  }
`

export const Response = styled.div`
  display: flex;
  padding: 0 30px;

  > button {
    cursor: default !important;
  }
`

export const EndMessage = styled.div`
  text-align: center;
  margin: 20px 0;
`
