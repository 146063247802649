import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Typography, styles, ITheme, utils } from '@ten-x/fe-lib-ui2020'

import { ISitemapGroup } from '../../sitemap/types'
import sitemapSource from '../../sitemap/sitemap.js'

const { Title } = Typography
const { styled } = styles
const sitemapUrls: ISitemapGroup[] = sitemapSource
const { searchFiltersDomain } = utils

const Sitemap = ({ groups }: { groups: ISitemapGroup[] }) => {
  const availableGroups: string[] = ['srp-property-type', 'srp-states']
  const renderGroups = groups.filter((group) =>
    availableGroups.includes(group.key)
  )
  return (
    <SitemapPageContainer>
      <Helmet title="Sitemap" />
      {renderGroups.map((group) => (
        <SitemapGroupContainer key={group.key}>
          <Title color="black" size="medium">
            {group.name}
          </Title>
          <SitemapList length={group.pages.length}>
            {group.pages.map((page, i) => {
              let url = page.path
              if (page.path.includes('/search')) {
                url = searchFiltersDomain.filtersToURL(
                  searchFiltersDomain.URLToFilters(page.path, '/search'),
                  '/search'
                )
              }
              return (
                <SitemapEntry key={i}>
                  <a href={url}>{page.name}</a>
                </SitemapEntry>
              )
            })}
          </SitemapList>
        </SitemapGroupContainer>
      ))}
    </SitemapPageContainer>
  )
}

const SitemapPageContainer = styled.div`
  margin-top: 102px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 300px;
  width: 95%;
  max-width: 1440px;
  z-index: 2;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    margin-top: 132px;
    padding-right: 0;
  }
`

const SitemapGroupContainer = styled.div(
  ({ theme }) => `
    padding: ${theme.spacing(3, 0)};
  
    a:visited {
      color: ${theme.palette.blue}
    }
  
    a:hover {
      color: ${theme.palette.blue}
    }
  `
)

const SitemapEntry = styled.li`
  list-style: none;
  height: 20px;
  width: 33.3%;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.md}px) {
    width: 50%;
  }
  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    width: 100%;
  }
`

const SitemapList = styled.ul(
  ({ length, theme }: { length: number; theme: ITheme }) => `
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: ${Math.ceil(length / 3) * 20}px;

  @media (max-width: ${theme.screenBreakpoints.md}px) {
    max-height: ${Math.ceil(length / 2) * 20}px;
  }
  @media (max-width: ${theme.screenBreakpoints.sm}px) {
    max-height: unset;
  }
`
)

export default connect(() => {
  return {
    groups: sitemapUrls,
  }
})(Sitemap)
