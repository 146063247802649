/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { Typography } from '@ten-x/fe-lib-ui2020'

import MSG from '../../constants/messages'

const { Title, Paragraph } = Typography

const Success = () => {
  return (
    <Fragment>
      <CongratsTitle color="black" data-elm-id="reset-email-success-title">
        {MSG.CONFIRMATION_RESENT}
      </CongratsTitle>
      <Paragraph color="black" data-elm-id="reset-email-message">
        {MSG.CHECK_EMAIL_TO_CONTINUE}
      </Paragraph>
    </Fragment>
  )
}

const CongratsTitle = styled(Title)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(6)};

  @media (max-width: ${theme.screenBreakpoints.lg}px) {
    &.ant-typography {
      font-size: 32px;
    }
  }

  @media (max-width: ${theme.screenBreakpoints.sm}px) {
    &.ant-typography {
      font-size: 26px;
    }
  }
`
)

export default Success
