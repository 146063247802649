/**
 * TODO: Figure out how to test redirections
 */
import { get } from 'lodash'
import { utils } from '@ten-x/fe-lib-ui2020'

import { isZip } from '../../../../utils/number'
import { toBase64 } from '../../../../utils/encoding'

const {
  searchFiltersDomain: { filtersToURL },
} = utils

export function searchRedirect(selection, propertyType = '', assetType) {
  let city = get(selection, 'value.city', '')
  let state = get(selection, 'value.state', '')
  let zip = get(selection, 'value.zip', '')
  const county = get(selection, 'value.county', '')
  const property = get(selection, 'value.property', '')
  const matchId = get(selection, 'value.matchId')
  const matchType = get(selection, 'value.matchType')
  const location = get(selection, 'value.location')
  const label = selection?.label || selection?._input || ''

  // special handling for zip that contains both "zip, city, and state", e.g. "10002, New York, NY"
  if (!zip && selection?.label && selection?.subLabel === 'Zip') {
    const labelParts = selection.label.split(',')
    if (isZip(labelParts[0])) {
      zip = labelParts[0]
      city = ''
      state = ''
    }
  }

  // new search key
  let sk = ''
  if (location && location.Lon && location.Lat) {
    sk = toBase64(`lon=${location.Lon}&lat=${location.Lat}`)
  } else if (matchId && matchType) {
    sk = toBase64(`id=${matchId}&type=${matchType}`)
  }

  const filters = {
    city: [city],
    state: [state],
    zip: [zip],
    county: [county],
    propSupers: (Array.isArray(propertyType) && propertyType) || [propertyType],
    property: [property],
    search: !city && !county && !state && !zip && !property ? [label] : [''],
    assetGrouping: Array.isArray(assetType) ? assetType[0] : assetType,
    sk,
  }

  if (typeof window !== 'undefined') {
    if (!window.location.origin) {
      const port = window.location.port ? ':' + window.location.port : ''
      window.location.origin = `${window.location.protocol}//${window.location.hostname}${port}`
    }

    const newUrl = window.location.origin + filtersToURL(filters)

    window.location.assign(newUrl)
  }
}
