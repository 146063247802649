/**
 * These are all the actions that can be fired in the app and processed to the reducers. We add
 * them here so we can also share them throughout the app instead of using strings everywhere. For
 * example, we need to reference these in the reducers, in all dispatch calls, and in the unit tests.
 */
module.exports = {
  // User init/login
  USER_INIT_START: 'USER_INIT_START',
  USER_INIT_SUCCESS: 'USER_INIT_SUCCESS',
  USER_INIT_FAIL: 'USER_INIT_FAIL',

  // User logout
  AUTH_LOGOUT_START: 'AUTH_LOGOUT_START',
  AUTH_LOGOUT_SUCCESS: 'AUTH_LOGOUT_SUCCESS',
  AUTH_LOGOUT_FAIL: 'AUTH_LOGOUT_FAIL',

  // User Resend confirmation email
  RESEND_CONFIRM_EMAIL_START: 'RESEND_CONFIRM_EMAIL_START',
  RESEND_CONFIRM_EMAIL_SUCCESS: 'RESEND_CONFIRM_EMAIL_SUCCESS',
  RESEND_CONFIRM_EMAIL_FAIL: 'RESEND_CONFIRM_EMAIL_FAIL',

  // User Confirm
  CONFIRM_USER_START: 'CONFIRM_USER_START',
  CONFIRM_USER_SUCCESS: 'CONFIRM_USER_SUCCESS',
  CONFIRM_USER_FAIL: 'CONFIRM_USER_FAIL',

  // User Activate
  ACTIVATE_START: 'ACTIVATE_START',
  ACTIVATE_SUCCESS: 'ACTIVATE_SUCCESS',
  ACTIVATE_FAIL: 'ACTIVATE_FAIL',

  // Assets
  ASSETS_REQUESTED: 'ASSETS_REQUESTED',
  ASSETS_RECEIVED: 'ASSETS_RECEIVED',

  // saved Assets
  SAVED_ASSETS_INIT: 'SAVED_ASSETS_INIT',
  SAVED_ASSETS_SUCCESS: 'SAVED_ASSETS_SUCCESS',
  SAVED_ASSETS_FAIL: 'SAVED_ASSETS_FAIL',
  UPDATE_SAVED_ASSETS: 'UPDATE_SAVED_ASSETS',

  // Shared Messages
  SHARED_MESSAGE_UPDATE: 'SHARED_MESSAGE_UPDATE',
  SHARED_MESSAGE_CLOSE_START: 'SHARED_MESSAGE_CLOSE_START',
  SHARED_MESSAGE_CLOSE: 'SHARED_MESSAGE_CLOSE',
  SHARED_MESSAGE_CLOSE_FAIL: 'SHARED_MESSAGE_CLOSE_FAIL',
}
