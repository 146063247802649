/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Typography } from '@ten-x/fe-lib-ui2020'

import { formatPhoneNumber } from '../../utils/format'
import MSG from '../../constants/messages'

const { Title, Paragraph } = Typography

const Message = ({ isConfirmSuccess }) => {
  const phoneNumber = MSG.TENX_PHONE_NUMBER
  const formatedPhoneNumber = formatPhoneNumber(phoneNumber)

  return (
    <Fragment>
      {isConfirmSuccess ? (
        <MessageTitle color="black" data-elm-id="confirm-broker-success-title">
          {MSG.CONFIRM_BROKER_CONGRAT}
        </MessageTitle>
      ) : (
        <MessageTitle color="black" data-elm-id="confirm-broker-error-title">
          {MSG.CONFIRM_BROKER_ERROR}
        </MessageTitle>
      )}
      <QuestionsTitle
        level={3}
        color="black"
        data-elm-id="confirm-broker-sub-title"
      >
        {MSG.QUESTIONS_Q}
      </QuestionsTitle>
      <Paragraph color="black" data-elm-id="confirm-broker-message">
        {MSG.CALL_US} <a href={`tel:${phoneNumber}`}>{formatedPhoneNumber}</a>,{' '}
        {MSG.CALL_HOURS}
      </Paragraph>
    </Fragment>
  )
}

Message.propTypes = {
  isConfirmSuccess: PropTypes.bool.isRequired,
}

const MessageTitle = styled(Title)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(6)};

  @media (max-width: ${theme.screenBreakpoints.lg}px) {
    &.ant-typography {
      font-size: 32px;
    }
  }

  @media (max-width: ${theme.screenBreakpoints.sm}px) {
    &.ant-typography {
      font-size: 26px;
    }
  }
`
)

const QuestionsTitle = styled(Title)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`

export default Message
