/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import configment from '@ten-x/configment'
import React, { useState, useRef, useEffect } from 'react'
import { Carousel } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'

import { adaptAssetFromAPI } from '../../../../transformers/assetAdapter'
import {
  getRecentProperties,
  getLastInterestModalDate,
  sendRecentPropertiesInterest,
} from '../../../../data_providers/user'

import {
  InterestConfirmationModal,
  Title,
  PropertyWrapper,
  BackgroundExtension,
  StyledPropertyTile,
  LeftArrowButton,
  RightArrowButton,
  Dots,
  Dot,
  Response,
  InterestButton,
  InterestButtons,
  UndecidedButton,
} from './styles'

export const InterestConfirmationModalComponent = ({ user }) => {
  const [showModal, setShowModal] = useState(false)
  const [properties, setProperties] = useState([])
  const [responses, setResponses] = useState(
    properties.map((p) => ({ listing_id: p.listingId, selected_option: '' }))
  )
  const [currentProperty, setCurrentProperty] = useState(0)
  const carrousel = useRef(null)

  useEffect(() => {
    // Check if it's been 3 days since last modal
    // if that is the case, fetch the properties and show the modal
    const fetchLastModalDate = async () => {
      try {
        const lastModalDateResponse = await getLastInterestModalDate()
        const lastModalDate = lastModalDateResponse.body
        if (
          !lastModalDate ||
          (lastModalDate &&
            Math.abs(moment(lastModalDate).diff(moment.now(), 'days')) >=
              configment.get('INTEREST_MODAL_INTERVAL_IN_DAYS', 3))
        ) {
          fetchRecentProperties()
        }
      } catch (error) {
        console.error('Error fetching the last insterest modal date: ', error)
        setShowModal(false)
      }
    }
    fetchLastModalDate()
  }, [])
  const fetchRecentProperties = async () => {
    try {
      const recentPropertiesResponse = await getRecentProperties()
      const recentProperties = recentPropertiesResponse.body
      if (
        recentProperties &&
        Array.isArray(recentProperties) &&
        recentProperties.length > 0
      ) {
        setProperties(recentProperties.map((p) => adaptAssetFromAPI(p)))
        setResponses(
          recentProperties.map((p) => ({
            listing_id: p.listingId,
            selected_option: '',
          }))
        )
        setShowModal(true)
      } else {
        setShowModal(false)
      }
    } catch (error) {
      console.error('Error trying to fetch the recent properties: ', error)
      setShowModal(false)
    }
  }
  const handleResponse = (currentResponse) => async () => {
    const newResponses = responses.map((res, index) =>
      index === currentProperty ? currentResponse : res
    )
    const nextProperty = newResponses
      .map((res) => res.selected_option)
      .indexOf('')
    if (nextProperty === -1) {
      setShowModal(false)
      try {
        await sendRecentPropertiesInterest({ responses: newResponses })
      } catch (error) {
        console.error('Error trying to save interest responses: ', error)
      }
    } else {
      setResponses(newResponses)
      goToPage(nextProperty)
    }
  }
  const onPropertyChange = (index) => {
    setCurrentProperty(index)
  }
  const nextPage = () => {
    if (carrousel && carrousel.current) {
      carrousel.current.next()
    }
  }
  const prevPage = () => {
    if (carrousel && carrousel.current) {
      carrousel.current.prev()
    }
  }
  const goToPage = (page) => {
    if (carrousel && carrousel.current) {
      carrousel.current.goTo(page)
    }
  }
  if (responses.length === 0) return <div />
  return (
    <InterestConfirmationModal
      visible={showModal}
      footer={false}
      closable={false}
    >
      <Title>Are you still interested in this property?</Title>
      <Carousel dots={false} afterChange={onPropertyChange} ref={carrousel}>
        {properties.map((property) => (
          <PropertyWrapper key={property.listingId}>
            <BackgroundExtension>
              <StyledPropertyTile
                pdsPlacard={property}
                clickable={false}
                showPrice={false}
              />
            </BackgroundExtension>
          </PropertyWrapper>
        ))}
      </Carousel>
      <LeftArrowButton direction="left" onClick={prevPage} />
      <RightArrowButton direction="right" onClick={nextPage} />
      <Dots>
        {properties.map((property, index) => (
          <Dot key={property.listingId} current={index === currentProperty} />
        ))}
      </Dots>
      {responses[currentProperty].selected_option !== '' ? (
        <Response>
          {responses[currentProperty].selected_option === 'interested' && (
            <InterestButton clickable={false} type="primary">
              Interested
            </InterestButton>
          )}
          {responses[currentProperty].selected_option === 'not_interested' && (
            <InterestButton clickable={false} type="secondary">
              Not Interested
            </InterestButton>
          )}
          {responses[currentProperty].selected_option === 'undecided' && (
            <InterestButton clickable={false}>Undecided</InterestButton>
          )}
        </Response>
      ) : (
        <div>
          <InterestButtons>
            <InterestButton
              onClick={handleResponse({
                listing_id: properties[currentProperty].listingId,
                selected_option: 'INTERESTED',
              })}
              type="primary"
            >
              Interested
            </InterestButton>
            <InterestButton
              onClick={handleResponse({
                listing_id: properties[currentProperty].listingId,
                selected_option: 'NOT_INTERESTED',
              })}
              type="secondary"
            >
              Not Interested
            </InterestButton>
          </InterestButtons>
          <InterestButtons>
            <UndecidedButton
              onClick={handleResponse({
                listing_id: properties[currentProperty].listingId,
                selected_option: 'UNDECIDED',
              })}
              type="link"
            >
              Undecided
            </UndecidedButton>
          </InterestButtons>
        </div>
      )}
    </InterestConfirmationModal>
  )
}
InterestConfirmationModalComponent.propTypes = {
  user: PropTypes.object.isRequired,
}
export default InterestConfirmationModalComponent
