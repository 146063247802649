import React from 'react'
import PropTypes from 'prop-types'
import { QueryClientProvider, QueryClient } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      // Query results are cached for 10 seconds
      staleTime: 10 * 1000,
      refetchOnWindowFocus: false,
    },
  },
})

const ReactQueryClientProvider = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
)

ReactQueryClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ReactQueryClientProvider
