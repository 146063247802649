const configment = require('@ten-x/configment')

const getUrlBase = () => configment.get('MS_UAA')

const getUriApi = (name) =>
  ({
    resendConfirmEmail: '/uaa/v1/users/resend_activation',
    confirmUser: '/uaa/v1/users/confirm/',
  }[name])

const getUrl = (name) => {
  return `${getUrlBase()}${getUriApi(name)}`
}

module.exports = {
  getUriApi,
  getUrlBase,
  getUrl,
}
