import { EPropertyTypeFilter } from '@ten-x/fe-lib-ui2020'

export const PROPERTY_TYPES = [
  {
    key: 'all',
    searchValue: '',
    label: 'ALL PROPERTY TYPES',
  },
  {
    key: 'office',
    searchValue: EPropertyTypeFilter.Office,
    label: 'Office',
  },
  {
    key: 'multi-family',
    searchValue: EPropertyTypeFilter.Multifamily,
    label: 'Multifamily',
  },
  {
    key: 'retail',
    searchValue: EPropertyTypeFilter.GeneralRetail,
    label: 'Retail',
  },
  {
    key: 'hotel',
    searchValue: EPropertyTypeFilter.Hospitality,
    label: 'Hotel',
  },
  {
    key: 'industrial',
    searchValue: EPropertyTypeFilter.Industrial,
    label: 'Industrial',
  },
  {
    key: 'other',
    searchValue: [
      EPropertyTypeFilter.Flex,
      EPropertyTypeFilter.Healthcare,
      EPropertyTypeFilter.Residential,
      EPropertyTypeFilter.ShoppingCenter,
      EPropertyTypeFilter.Specialty,
      EPropertyTypeFilter.SportsAndEntertainment,
    ],
    label: 'Other',
  },
]
