/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import get from 'lodash/get'
import { Col } from 'antd'
import { Button, styles } from '@ten-x/fe-lib-ui2020'

import { OPTIONS_DATA } from '../putToWorkData'

import stylesSCSS from './PersonasColumns.scss'

const cx = classnames.bind(stylesSCSS)

const { styled } = styles

export const LearnMoreButton = ({ elementId, track, label, url }) => {
  return (
    <LinkButton
      type="primary"
      size="default"
      href={url}
      data-elm-id={elementId}
    >
      {label}
    </LinkButton>
  )
}

LearnMoreButton.propTypes = {
  elementId: PropTypes.string,
  track: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.string,
}

const PersonasColumns = ({ urlList }) => {
  return OPTIONS_DATA.map((option) => {
    return (
      <Col key={option.key} span={24} md={8} className={cx('persona-column')}>
        <div>
          <div className={cx('heading')}>{option.heading}</div>
          <div className={cx('short-description')}>
            {option.shortDescription}
          </div>
          <div className={cx('description')}>{option.description}</div>
        </div>
        <LearnMoreButton
          elementId={option.elementId}
          track={option.track}
          label={option.buttonLabel}
          url={get(urlList, option.urlListPath, option.defaultURL)}
        />
      </Col>
    )
  })
}

PersonasColumns.propTypes = {
  urlList: PropTypes.object.isRequired,
}

export const LinkButton = styled(Button)`
  a&.ant-btn {
    width: fit-content;
  }
`

export default PersonasColumns
