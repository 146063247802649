import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { Row, Col } from 'antd'
import styled from '@emotion/styled'

import PersonasColumns from './components/PersonasColumns'
import styles from './styles.scss'

const cx = classnames.bind(styles)

const PutToWorkSection = ({ urlList }) => {
  return (
    <PlatformSectionContainer>
      <PlatformRowContainer>
        <Col xs={24}>
          <Row className={cx('put-to-work-section')} type="flex">
            <PutToWorkTitle span={24} md={16} lg={12}>
              <TitleContainer>
                Put the Ten-X platform to work for you.
              </TitleContainer>
            </PutToWorkTitle>
            <Col span={24}>
              <Row gutter={80} className={cx('options-row')} type="flex">
                <PersonasColumns urlList={urlList} />
              </Row>
            </Col>
          </Row>
        </Col>
      </PlatformRowContainer>
    </PlatformSectionContainer>
  )
}

PutToWorkSection.propTypes = {
  urlList: PropTypes.object.isRequired,
}

export const PlatformSectionContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.grayLight};
  background-image: url(https://cre-prd-img.imgix.net/site%2Fstatic%2Fwhite-XVector-Right.v2.svg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 1236px;

  min-height: 760px;
  position: relative;
  top: -1px;

  &::before {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;

    background-image: url(https://www-dvm.ten-x.com/images/site/static/TXBrand_Creative_Territories_Deck01.v1.png?auto=format,
      compress);
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: 1450px) {
      background-size: 0;
      padding-left: 0;
    }
  }
`

export const PlatformRowContainer = styled(Row)`
  max-width: 1440px;
  margin: auto;
  margin-left: 33%;

  @media (max-width: 1450px) {
    margin-left: 0;
  }

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.xxl * 2}px) {
    margin-left: auto;
    margin-right: auto;
  }
`

export const PutToWorkTitle = styled(Col)`
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 64px;
  line-height: 74px;
  font-weight: ${({ theme }) => theme.font.weight.normal};
  margin: 80px 0 20px;
  width: 100%;
  padding-right: 60px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    margin: 50px 0 20px;
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 36px;
    line-height: 42px;
    margin-top: 20px;
    padding-right: 0;
  }
`

export const TitleContainer = styled.h2`
  font-size: 64px;
  line-height: 75px;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    font-size: 36px;
    line-height: 46px;
  }
`

export default PutToWorkSection
