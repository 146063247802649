/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router'

import ConfirmAccountComponent from '../../components/confirmAccount'
import analytics from '../../services/analytics'
import MSG from '../../constants/messages'

const UserActivationPage = (props) => {
  const qs = new URLSearchParams(props.location.search)

  useEffect(() => {
    analytics.onPageLoadAccountConfirmed()
  }, [])

  const URLBasedParams = {
    invitationToken: qs.get('uid'),
  }

  return (
    <Fragment>
      <Helmet title={MSG.ACCOUNT_CONFIRMATION} />
      <ConfirmAccountComponent
        URLBasedParams={URLBasedParams}
        backToURL={qs.get('back') || ''}
      />
    </Fragment>
  )
}

UserActivationPage.propTypes = {
  location: PropTypes.any,
}

export default withRouter(UserActivationPage)
