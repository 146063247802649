/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { styles, Typography, RegistrationForm } from '@ten-x/fe-lib-ui2020'
import { notification, Row, Col } from 'antd'

import analytics from '../../services/analytics'
import ProcessingOverlay from '../../components/processingOverlay'
import UserProvider from '../../data_providers/user'
import MSG from '../../constants/messages'
import SuccessSplashScreen from '../../components/activation/Success'

const { styled } = styles
const { Title, Paragraph } = Typography

const ActivationPage = ({ match: { params } }) => {
  const [token] = useState(get(params, 'token'))
  const [gettingUserInformation, setGettingUserInformation] = useState(true)
  const [userInformation, setUserInformation] = useState()
  const [userEmail, setUserEmail] = useState('')
  const [showConfirmEmailContent, setShowConfirmEmailContent] = useState(false)

  useEffect(() => {
    let mounted = true

    analytics.onPageLoadUserInvite()

    async function getUserInvitationInfo() {
      try {
        const res = await UserProvider.getInvitationInfo({ args: { token } })
        mounted && setUserInformation(res.body)
      } catch (error) {
        console.error(error)
        notification.error({
          message: MSG.INVITE_CODE_INVALID_TITLE,
          description: MSG.INVITE_CODE_INVALID_BODY,
        })
      }
      mounted && setGettingUserInformation(false)
    }

    getUserInvitationInfo()

    return () => {
      mounted = false
    }
  }, [])

  function onActivationSuccess(values, userId) {
    // We don't want to cancel registration flow if analytics fail, swallow error
    try {
      analytics.onCreateAccount(values.marketing_opt, values, userId)
    } catch (error) {
      console.error(error)
    }
    setUserEmail(values.email)
    setShowConfirmEmailContent(true)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  if (gettingUserInformation) {
    return <ProcessingOverlay />
  }

  if (showConfirmEmailContent) {
    return (
      <ConfirmationEmailSplashContainer>
        <SuccessSplashScreen email={userEmail} />
      </ConfirmationEmailSplashContainer>
    )
  }

  return (
    <ActivationPageContainer>
      <Helmet title={MSG.ACTIVATE} />
      <SignupContentContainer>
        <Col span={24} md={18} lg={14} xl={12} xxl={10}>
          <TitleContainer color="black">Sign Up</TitleContainer>
          <SectionParagraph size="large" color="black">
            Welcome to Ten-X, the industry leader in online commercial real
            estate transactions. Signing up only takes a minute and is free.
          </SectionParagraph>
          <RegistrationForm
            showLoginLink={false}
            submitButtonLabel="SIGN UP"
            initialValues={
              userInformation && {
                first_name: userInformation.first_name,
                last_name: userInformation.last_name,
                email: userInformation.email,
                phone: userInformation.phones && userInformation.phones[0],
              }
            }
            token={token}
            onFormSubmitSuccess={onActivationSuccess}
          />
        </Col>
      </SignupContentContainer>
    </ActivationPageContainer>
  )
}

ActivationPage.propTypes = {
  match: PropTypes.object.isRequired,
}

const ActivationPageContainer = styled.div`
  margin-top: 102px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    margin-top: 132px;
  }
`

const SignupContentContainer = styled(Row)(
  ({ theme }) => `
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  padding: ${theme.spacing(8, 0)};
  text-align: left;
`
)

const TitleContainer = styled(Title)(
  ({ theme }) => `
  &.ant-typography {
    margin-bottom: ${theme.spacing(7)};
  }
`
)

const SectionParagraph = styled(Paragraph)(
  ({ theme }) => `
  &.ant-typography {
    margin-bottom: ${theme.spacing(4)};
  }
`
)

const ConfirmationEmailSplashContainer = styled.div`
  text-align: left;
  position: relative;
  z-index: 1;
`

export default ActivationPage
