/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { useLocation } from 'react-router'
import { styles } from '@ten-x/fe-lib-ui2020'
import { Row, Col } from 'antd'

import analytics from '../../services/analytics'
import ResendConfirmEmailComponent from '../../components/resendConfirmEmail'
import SharedMessageActions from '../../actions/sharedMessage'
import UserActions from '../../actions/user'
import types from '../../constants/prop_types'
import MSG from '../../constants/messages'

const { styled } = styles

const ResendConfirmEmail = ({
  resendConfirmEmailProgress,
  resendConfirmEmailAction,
}) => {
  const location = useLocation()
  const prefilledEmail = new URLSearchParams(location.search).get('email') || ''

  useEffect(() => {
    analytics.onPageLoadResendConfirmEmail()
  }, [])

  return (
    <Fragment>
      <Helmet title={MSG.RESEND_CONFIRM_EMAIL} />
      <ContentContainer data-elm-id="resend-confirm-email-container">
        <ResponsiveCol span={20} md={12} xl={9} xxl={7}>
          <ResendConfirmEmailComponent
            resendConfirmEmailProgress={resendConfirmEmailProgress}
            resendConfirmEmailSubmit={resendConfirmEmailAction}
            prefilledEmail={prefilledEmail}
          />
        </ResponsiveCol>
      </ContentContainer>
    </Fragment>
  )
}

ResendConfirmEmail.propTypes = {
  resendConfirmEmailProgress: types.resendConfirmEmailProgress.isRequired,
  resendConfirmEmailAction: PropTypes.func.isRequired,
}

const ContentContainer = styled(Row)`
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
`

const ResponsiveCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

function mapStateToProps(store) {
  const resendConfirmEmailProgress = get(
    store,
    'user.resendConfirmEmailProgress',
    {}
  )

  return {
    resendConfirmEmailProgress,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resendConfirmEmailAction: (email) =>
      dispatch(UserActions.resendConfirmEmail(email)).catch((error) => {
        const errorStatus = error.status

        const sharedMessageProps = {
          role: 'error',
          title: MSG.RESEND_CONFIRM_EMAIL_ERROR_TITLE,
          message: MSG.RESEND_CONFIRM_EMAIL_ERROR_BODY,
          isVisible: true,
          isCloseable: true,
        }
        if (errorStatus === 400) {
          const { message } = error
          if (message && message.includes('user is already confirmed')) {
            sharedMessageProps.message = MSG.ACCOUNT_ALREADY_CONFIRMED_BODY
          }
        } else if (errorStatus === 404) {
          sharedMessageProps.message = MSG.NO_ACCOUNT_ASSOCIATED_WITH_THAT_EMAIL
        }
        dispatch(SharedMessageActions.update(sharedMessageProps))

        throw error
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResendConfirmEmail)
