import React, { Component } from 'react'
import { connect } from 'react-redux'

import NotificationsComponent from '../../components/home/notifications'
import types from '../../constants/prop_types'

class SharedMessage extends Component {
  static propTypes = {
    sharedMessage: types.sharedMessage,
  }

  static defaultProps = {
    sharedMessage: {
      isVisible: false,
    },
  }

  render() {
    return <NotificationsComponent sharedMessage={this.props.sharedMessage} />
  }
}

export default connect((store) => ({
  sharedMessage: store.sharedMessage,
}))(SharedMessage)
