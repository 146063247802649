/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import PropTypes from 'prop-types'
import React, { Fragment, useState, useEffect } from 'react'
import {
  styles,
  Typography,
  Button,
  FormikInput,
  utils,
} from '@ten-x/fe-lib-ui2020'
import { Form } from 'formik-antd'
import { Formik } from 'formik'

import ProcessingOverlay from '../processingOverlay'
import types from '../../constants/prop_types'
import MSG from '../../constants/messages'

import Success from './Success'
import Error from './Error'

const { styled } = styles
const { Title } = Typography
const {
  formValidators: { validateEmail },
} = utils

const ResendConfirmEmailV2 = ({
  prefilledEmail,
  resendConfirmEmailProgress,
  resendConfirmEmailSubmit,
}) => {
  const [resetSuccess, setResetSuccess] = useState(false)
  const [isResendingConfirmEmail, setIsResendingConfirmEmail] = useState(false)
  const [resendingConfirmEmailError, setResendingConfirmEmailError] = useState()

  useEffect(() => {
    if (resendConfirmEmailProgress) {
      setIsResendingConfirmEmail(resendConfirmEmailProgress.loading)
    }
  }, [resendConfirmEmailProgress])

  async function onEmailSubmit(values) {
    try {
      await resendConfirmEmailSubmit(values.email)
      setResetSuccess(true)
    } catch (error) {
      console.error(error)
      setResendingConfirmEmailError(error)
    }
  }

  if (isResendingConfirmEmail) {
    return <ProcessingOverlay />
  }

  if (resetSuccess) {
    return <Success />
  }

  if (resendingConfirmEmailError) {
    return <Error error={resendingConfirmEmailError} />
  }

  return (
    <Fragment>
      <TitleContainer color="black" data-elm-id="resend-confirm-email-title">
        {MSG.RESEND_CONFIRM_EMAIL}
      </TitleContainer>
      <Formik
        validateOnBlur
        validateOnChange
        validateOnMount={!!prefilledEmail}
        initialValues={{
          email: prefilledEmail,
        }}
        onSubmit={onEmailSubmit}
        data-elm-id="rce-form"
      >
        <Form>
          <Form.Item name="email" validate={validateEmail}>
            <FormikInput name="email" placeholder="E-mail Address" />
          </Form.Item>
          <SubmitButton
            block
            submit
            data-elm-id="rce-submit-button"
            type="primary"
          >
            {MSG.SUBMIT}
          </SubmitButton>
        </Form>
      </Formik>
    </Fragment>
  )
}

ResendConfirmEmailV2.propTypes = {
  resendConfirmEmailProgress: types.resendConfirmEmailProgress.isRequired,
  resendConfirmEmailSubmit: PropTypes.func.isRequired,
  prefilledEmail: PropTypes.string,
}

export default ResendConfirmEmailV2

const TitleContainer = styled(Title)`
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.lg}px) {
    &.ant-typography {
      font-size: 32px;
    }
  }

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    &.ant-typography {
      font-size: 26px;
    }
  }
`

const SubmitButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(6)};
`
