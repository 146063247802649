/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import { withRouter, useParams } from 'react-router'

import ConfirmBroker from '../../components/confirmBroker'
import { setBidderBrokerRegistration } from '../../data_providers/bidder'
import MSG from '../../constants/messages'

export const PENDING = 'PENDING'
export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'

const ConfirmBrokerPage = () => {
  const [confirmationStatus, setConfirmationStatus] = useState(PENDING)
  const { brokerRegistrationId } = useParams()

  useEffect(() => {
    let unmounted = false

    const confirmBiddersBroker = async () => {
      try {
        await setBidderBrokerRegistration(brokerRegistrationId)

        if (unmounted) return
        setConfirmationStatus(SUCCESS)
      } catch (err) {
        setConfirmationStatus(ERROR)
        console.error(err)
      }
    }

    confirmBiddersBroker()

    return () => (unmounted = true)
  }, [])

  return (
    <>
      <Helmet title={MSG.BROKER_CONFIRMATION} />
      <ConfirmBroker confirmationStatus={confirmationStatus} />
    </>
  )
}

export default withRouter(ConfirmBrokerPage)
