import { combineReducers } from 'redux'

import sharedMessage from './sharedMessage'
import user from './user'
import assets from './assets'

const appReducer = combineReducers({
  sharedMessage,
  user,
  assets,
  error: (state = {}) => state,
  config: (state = {}) => state,
})

export default appReducer
