/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { Typography, styles } from '@ten-x/fe-lib-ui2020'
import configment from '@ten-x/configment'

import MSG from '../../constants/messages'

const { Title, Text, Paragraph } = Typography
const { styled } = styles

const Error = ({ error }) => {
  const [errorMessage, setErrorMessage] = useState(
    MSG.RESEND_CONFIRM_EMAIL_ERROR_BODY
  )

  useEffect(() => {
    const errorStatus = error.status

    if (errorStatus === 400) {
      const { message } = error
      if (message && message.includes('user is already confirmed')) {
        setErrorMessage(MSG.ACCOUNT_ALREADY_CONFIRMED_BODY)
      }
    } else if (errorStatus === 404) {
      setErrorMessage(MSG.NO_ACCOUNT_ASSOCIATED_WITH_THAT_EMAIL)
    }
  }, [error])

  function redirect() {
    const config = configment.get()
    window.location = `${config.URL_CRE_BASE_PREFIX}/login`
  }

  return (
    <Fragment>
      <ErrorTitle color="black" data-elm-id="resend-confirm-email-error-title">
        {MSG.RESEND_CONFIRM_EMAIL_ERROR_TITLE}
      </ErrorTitle>
      <Paragraph color="black" size="large">
        {errorMessage}
      </Paragraph>
      <GoToLogin
        color="green"
        size="large"
        data-elm-id="resend-confirm-email-error-message"
        onClick={redirect}
      >
        {MSG.RESEND_CONFIRM_EMAIL_ERROR_LOGIN}
      </GoToLogin>
    </Fragment>
  )
}

Error.propTypes = {
  error: PropTypes.object.isRequired,
}

const ErrorTitle = styled(Title)`
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`

const GoToLogin = styled(Text)`
  cursor: pointer;
  font-weight: ${({ theme }) => theme.font.weight.normal};
  margin-top: ${({ theme }) => theme.spacing(3)};

  :hover {
    color: ${({ theme }) => theme.colors.link.primary};
  }
`

export default Error
