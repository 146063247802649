import ACTION_TYPES from '../constants/actionTypes'

const sharedMessageActions = {
  delayedCloseTimeout: null,

  update: function (value) {
    return {
      type: ACTION_TYPES.SHARED_MESSAGE_UPDATE,
      value: value,
    }
  },

  close: function () {
    return {
      type: ACTION_TYPES.SHARED_MESSAGE_CLOSE,
    }
  },

  delayedClose: function (delay) {
    return {
      types: [
        ACTION_TYPES.SHARED_MESSAGE_CLOSE_START,
        ACTION_TYPES.SHARED_MESSAGE_CLOSE,
        ACTION_TYPES.SHARED_MESSAGE_CLOSE_FAIL,
      ],
      promise: new Promise((resolve) => {
        clearTimeout(this.delayedCloseTimeout)
        this.delayedCloseTimeout = setTimeout(resolve, delay)
      }),
    }
  },
}

export default sharedMessageActions
