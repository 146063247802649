/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import PropTypes from 'prop-types'
import React from 'react'
import { Row, Col } from 'antd'
import styled from '@emotion/styled'

import ProcessingOverlay from '../processingOverlay'
import { PENDING, SUCCESS } from '../../containers/confirmBroker'

import Message from './message'

const ConfirmAccount = (props) => {
  const { confirmationStatus } = props

  return (
    <ContentContainer data-elm-id="confirm-account-container">
      {confirmationStatus === PENDING ? (
        <ProcessingOverlay />
      ) : (
        <ResponsiveCol span={20} md={12} lg={10} xl={8} xxl={6}>
          <Message isConfirmSuccess={confirmationStatus === SUCCESS} />
        </ResponsiveCol>
      )}
    </ContentContainer>
  )
}

ConfirmAccount.propTypes = {
  confirmationStatus: PropTypes.string.isRequired,
}

const ContentContainer = styled(Row)`
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
`

const ResponsiveCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export default ConfirmAccount
