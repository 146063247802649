const fetch = require('@ten-x/core-utils/src/helpers/fetch')
const configment = require('@ten-x/configment')

const UserProvider = {
  get: ({ req }) => {
    return fetch(`${configment.get('LIB_AUTH_PATH_PREFIX')}/getUserInfo`, {
      req,
    })
      .then((res) => res.body)
      .catch((err) => {
        console.error(err)
        return { logged_in: false }
      })
  },

  getInvitationInfo: ({ req, args }) => {
    return fetch(
      `${configment.get('CRE_MS_UAA_KUB_URL')}/uaa/v1/users/search/invitation/${
        args.token
      }`,
      { req }
    )
  },

  confirmUser: (req, body) => {
    return fetch(
      `${configment.get('CRE_MS_UAA_KUB_URL')}/uaa/v1/users/username`,
      {
        req: req,
        method: 'POST',
        body: body,
      }
    )
      .then((response) => response.body)
      .catch((err) => {
        throw err.body || err
      })
  },

  getSavedAssets(req) {
    const apiUrl = `${configment.get('BFF_URL')}/api/v1/saved-listings/me`
    return fetch(apiUrl, {
      req,
      method: 'GET',
    })
      .then(({ body }) => {
        return {
          total: body.data.length,
          saved_properties: body.data.map(({ enterpriseListingId }) => ({
            enterpriseListingId,
          })),
        }
      })
      .catch((e) => {
        console.error(e)
        return { saved_properties: [] }
      })
  },

  addSavedAsset(req, enterpriseListingId) {
    const apiUrl = `${configment.get('BFF_URL')}/api/v1/saved-listings`
    return fetch(apiUrl, {
      req,
      method: 'POST',
      queryParams: {
        enterpriseListingId,
      },
    })
      .then((response) => response.body)
      .catch((err) => {
        throw err.body || err
      })
  },

  deleteSavedAsset(req, enterpriseListingId) {
    const apiUrl = `${configment.get('BFF_URL')}/api/v1/saved-listings`
    return fetch(apiUrl, {
      req,
      method: 'DELETE',
      queryParams: {
        enterpriseListingId,
      },
    })
      .then((response) => response.body)
      .catch((err) => {
        throw err.body || err
      })
  },
  getLastInterestModalDate({ req } = {}) {
    return fetch(
      `${configment.get(
        'CRE_MS_PROFILE_URL'
      )}/cre/profile/v1/interested_suggestions/users/suggestions/last_seen_time`,
      { req }
    )
  },
  getRecentProperties({ req } = {}) {
    return fetch(
      `${configment.get(
        'CRE_MS_PROFILE_URL'
      )}/cre/profile/v1/confidentiality_agreements/me`,
      {
        req,
        queryParams: {
          status: 'ACCEPTED',
          only_active: true,
        },
      }
    )
  },
  sendRecentPropertiesInterest({ req = {}, responses }) {
    return fetch(
      `${configment.get(
        'CRE_MS_PROFILE_URL'
      )}/cre/profile/v1/interested_suggestions/user/answers`,
      {
        req,
        method: 'POST',
        body: responses,
      }
    )
  },
}

module.exports = UserProvider
