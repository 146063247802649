const fetch = require('@ten-x/core-utils/src/helpers/fetch')
const configment = require('@ten-x/configment')
const _empty = require('lodash/isEmpty')

const searchProvider = {
  get: (req, query, options, showWebPersonalization) => {
    let requestOptions = { req, queryParams: query }

    if (!_empty(options)) {
      requestOptions = Object.assign({}, requestOptions, options)
    }

    let uri = `${configment.get('BFF_URL')}/api/v1/listing/featured`
    if (showWebPersonalization) {
      uri = `${configment.get('BFF_URL')}/api/v1/listing/recommended`
    }

    return fetch(uri, requestOptions).then((res) => res)
  },
}

module.exports = searchProvider
