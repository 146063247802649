import ACTION_TYPES from '../constants/actionTypes'
import savedAssetsTransformer from '../transformers/savedAssets'

const defaultState = {
  logged_in: false,
  signupProgress: {
    loading: false,
    error: null,
  },
  resendConfirmEmailProgress: {
    loading: false,
    error: null,
  },
  confirmUserProgress: {
    loading: false,
    error: null,
  },
  fetchSavedAssetsProgress: {
    loading: false,
    error: null,
  },
}

export default (state, action) => {
  if (typeof state === 'undefined') {
    return defaultState
  }

  switch (action.type) {
    // User basic info/login
    case ACTION_TYPES.USER_INIT_START: {
      return Object.assign({}, action.value, {
        loginProgress: {
          loading: true,
          error: null,
        },
      })
    }
    case ACTION_TYPES.USER_INIT_SUCCESS: {
      return Object.assign({}, state, action.value, {
        loginProgress: {
          loading: false,
          error: null,
        },
      })
    }
    case ACTION_TYPES.USER_INIT_FAIL: {
      return Object.assign({}, action.value, {
        loginProgress: {
          loading: false,
          error: action.value,
        },
      })
    }

    // User logout
    case ACTION_TYPES.AUTH_LOGOUT_SUCCESS:
      return defaultState

    // Resend confirmation email
    case ACTION_TYPES.RESEND_CONFIRM_EMAIL_START: {
      return Object.assign({}, state, {
        resendConfirmEmailProgress: {
          loading: true,
          error: null,
        },
      })
    }
    case ACTION_TYPES.RESEND_CONFIRM_EMAIL_SUCCESS: {
      return Object.assign({}, state, {
        resendConfirmEmailProgress: {
          loading: false,
          error: null,
        },
      })
    }
    case ACTION_TYPES.RESEND_CONFIRM_EMAIL_FAIL: {
      return Object.assign({}, state, {
        resendConfirmEmailProgress: {
          loading: false,
          error: action.error,
        },
      })
    }

    // User Confirm
    case ACTION_TYPES.CONFIRM_USER_START: {
      return Object.assign({}, state, {
        confirmUserProgress: {
          loading: true,
          error: null,
        },
      })
    }
    case ACTION_TYPES.CONFIRM_USER_SUCCESS: {
      return Object.assign({}, state, {
        confirmUserProgress: {
          loading: false,
          error: null,
        },
      })
    }
    case ACTION_TYPES.CONFIRM_USER_FAIL: {
      return Object.assign({}, state, {
        confirmUserProgress: {
          loading: false,
          error: action.value,
        },
      })
    }

    case ACTION_TYPES.UPDATE_SAVED_ASSETS: {
      const newState = Object.assign({}, state)
      if (newState && newState.saved_assets) {
        const filteredAssets = newState.saved_assets.filter(
          (obj) =>
            obj.enterpriseListingId === action.payload.enterpriseListingId
        )
        if (filteredAssets.length) {
          newState.saved_assets.splice(
            newState.saved_assets.indexOf(filteredAssets[0]),
            1
          )
        } else {
          newState.saved_assets.push({
            enterpriseListingId: action.payload.enterpriseListingId,
          })
        }
      }
      return Object.assign({}, newState)
    }
    case ACTION_TYPES.SAVED_ASSETS_INIT:
      return Object.assign({}, state, {
        fetchSavedAssetsProgress: {
          loading: true,
          errors: null,
        },
      })
    case ACTION_TYPES.SAVED_ASSETS_FAIL:
      return Object.assign({}, state, {
        fetchSavedAssetsProgress: {
          loading: false,
          errors: action.error,
        },
      })
    case ACTION_TYPES.SAVED_ASSETS_SUCCESS: {
      const savedAssetResponse = savedAssetsTransformer.api2fe(action.value)
      return Object.assign(
        {},
        state,
        {
          fetchSavedAssetsProgress: {
            loading: false,
            error: null,
          },
        },
        savedAssetResponse
      )
    }
    default:
      return state
  }
}
