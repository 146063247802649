/**
 * TODO: As current testing framework doesn't support testing styled
 * components or react hooks properly ignore this file
 */
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import Helmet from 'react-helmet'
import ClassNames from 'classnames/bind'
import {
  Header,
  AuthModal,
  Footer,
  MaintenanceReleaseBanner,
  SilentAuth,
  styles,
  SystemMessageBanner,
  utils,
} from '@ten-x/fe-lib-ui2020'

import UserActions from '../../actions/user'
import analytics from '../../services/analytics'
import SavedAssetsService from '../../services/saved_assets'
import MSG from '../../constants/messages'
import ProcessingOverlay from '../processingOverlay'
import systemMessageProvider from '../../../common/data_providers/system_messages'

import HeroSection from './sections/hero'
import PoweringTransactionsSection from './sections/poweringTransactions'
import FeaturedPropertiesSection from './sections/featuredProperties'
import PutToWorkSection from './sections/putToWork'
import AccelerateCRESection from './sections/accelerateCRE'
import TestimonialsSection from './shared/testimonials'
import { ENTERPRISE_IMAGES } from './shared/testimonials/testimonialsData'
import cls from './styles.scss'
import InterestConfirmationModal from './shared/interestConfirmationModal'

const cx = ClassNames.bind(cls)

const { withThemeProvider } = styles

const SIGNUP_SUCCESS_QS_KEY = 'signup'
const SAVE_ASSET_QS_KEY = 'save_asset'

export const HomeV3Layout = (props) => {
  const {
    featureFlags,
    urlListFooter,
    urlListHeader,
    user,
    assets,
    dispatch,
    location,
    history,
  } = props

  const queryParams = new URLSearchParams(location.search)
  const isSignupSuccess = queryParams.get(SIGNUP_SUCCESS_QS_KEY) === 'SUCCESS'

  const [showSignupSuccessScreen] = useState(isSignupSuccess)
  const [authModalVisible, setAuthModalVisible] = useState(false)
  const [savingAsset, setSavingAsset] = useState(null)
  const [backToUrl, setBackToUrl] = useState('/')
  const [systemMessage, setSystemMessage] = useState(null)

  useEffect(() => {
    analytics.onPageLoadHome()
    utils.createUtmLocalStorage()

    // Favourite asset flow
    const assetId = queryParams.get(SAVE_ASSET_QS_KEY)
    if (assetId) {
      SavedAssetsService.saveAssetById(assetId)

      queryParams.delete(SAVE_ASSET_QS_KEY)
    }

    // This will remove the signup query param but leave the others
    // we could potentially have in the future
    // Why we do this? We don't want the user to reload the page and
    // keep showing the signup success screen
    if (isSignupSuccess) {
      queryParams.delete(SIGNUP_SUCCESS_QS_KEY)

      setAuthModalVisible(true)

      // Track user creation
      analytics.onCreateAccount(user)
    }

    // Replace history
    const searchValue = queryParams.toString()

    if (searchValue) {
      history.replace({
        pathname: location.pathname,
        search: `?${searchValue}`,
      })
    } else {
      history.replace(location.pathname)
    }
  }, [])

  useEffect(() => {
    const showSystemMessageBanner = _get(
      featureFlags,
      'fe.tenx.enable-system-message-banner',
      false
    )

    if (showSystemMessageBanner) {
      systemMessageProvider
        .getSystemMessage()
        .then((message) => setSystemMessage(message))
        .catch((error) => {
          console.error(`Error retrieving system message: ${error}`)
        })
    }
  }, [])

  function onSilentLoginSuccess() {
    dispatch(UserActions.init())
    dispatch(UserActions.getSavedAssets())

    // Identify user
    analytics.onSignIn()
  }

  function showSignupModal() {
    setAuthModalVisible(true)
  }

  function showLoginModal(redirect = '/') {
    setBackToUrl(redirect)
    setAuthModalVisible(true)
  }

  function hideAuthModal() {
    if (savingAsset !== null) {
      setSavingAsset(null)
    }
    setAuthModalVisible(false)
  }

  function onRegistrationFinish() {
    // We cannot use react-router here because we are going to
    // another application (we need to make an http request)
    window.location.href = urlListHeader?.search || '/search'
  }

  function handleSaveAsset(asset) {
    if (!_get(user, 'logged_in', false)) {
      const assetId = _get(asset, 'id.global_property_id', null)
      showLoginModal(`/commercial/?${SAVE_ASSET_QS_KEY}=${assetId}`)
      setSavingAsset(asset)
    } else {
      SavedAssetsService.saveOrDeleteAsset(user, asset)
    }
  }

  const isLoggedIn = _get(user, 'logged_in', false)
  const isLoggingIn = _get(user, 'loginProgress.loading', false)
  const isSavingAsset = !!savingAsset

  const showMaintenanceBanner = _get(
    featureFlags,
    'fe.tenx.enable-maintenance-banner',
    false
  )

  const showCoporateSponsor = _get(
    featureFlags,
    'fe.tenx.enable-sponsor-page',
    false
  )

  const consolidatedUrls = { ...urlListHeader, ...urlListFooter }

  return (
    <div data-elm-id="homepage-container-v3">
      {systemMessage && (
        <SystemMessageBanner message={systemMessage.site_message_content} />
      )}
      <Helmet>
        <title>{MSG.HELMET_TITLE}</title>
        <meta name="description" content={MSG.HELMET_DESCRIPTION} />
        <link rel="canonical" href={MSG.HELMET_CANONICAL} />
      </Helmet>
      <div data-elm-id="home-v3-container" className={cx('home-v3-container')}>
        {(isLoggingIn || isSavingAsset) && <ProcessingOverlay />}
        {showMaintenanceBanner && <MaintenanceReleaseBanner />}
        <FixedHeader
          menuBar={showCoporateSponsor}
          transparentMode
          featureFlags={featureFlags}
          urlList={consolidatedUrls}
          user={user}
          showLoginModal={showLoginModal}
          showSearchBox={false}
        />
        <HeroSection featureFlags={featureFlags} />
        <FeaturedPropertiesSection
          user={user}
          assets={assets}
          dispatch={dispatch}
          onSaveAsset={handleSaveAsset}
          featureFlags={featureFlags}
        />
        <PutToWorkSection urlList={consolidatedUrls} />
        <TestimonialsSection
          carouselAssets={ENTERPRISE_IMAGES}
          title="Join the thousands of brokers selling twice as fast on Ten-X !"
        />
        <PoweringTransactionsSection />
        <AccelerateCRESection
          user={user}
          showSignupModal={showSignupModal}
          urlList={consolidatedUrls}
        />
        <Footer urlList={consolidatedUrls} />
      </div>
      <AuthModal
        showSignupSuccessScreen={showSignupSuccessScreen}
        visible={authModalVisible}
        onCancel={hideAuthModal}
        onRegistrationFinish={onRegistrationFinish}
        backTo={backToUrl}
      />
      {isLoggedIn ? null : (
        <SilentAuth onSilentLoginSuccess={onSilentLoginSuccess} />
      )}

      <InterestConfirmationModal user={user} />
    </div>
  )
}

export const FixedHeader = styled(Header)`
  margin-bottom: -102px;

  @media (max-width: ${({ theme }) => theme.screenBreakpoints.sm}px) {
    margin-bottom: -132px;
    height: 90px;
  }
`

HomeV3Layout.propTypes = {
  featureFlags: PropTypes.object.isRequired,
  urlListFooter: PropTypes.object.isRequired,
  urlListHeader: PropTypes.object.isRequired,
  assets: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withThemeProvider(HomeV3Layout)
