import querystring from 'querystring'

import _get from 'lodash/get'

import searchProvider from '../data_providers/search'
import store from '../store'

const searchService = {
  get: (_user, req) => {
    const state = (store() && store().getState()) || {}
    const user = _user || state.user || {}
    const { config } = state

    return new Promise((resolve) => {
      // Only request the first 9 featured properties
      const apiQueryString = querystring.stringify({ count: 9 })
      const featureFlags = _get(config, 'featureFlags', {})
      const showWebPersonalization = _get(
        featureFlags,
        'fe.cre.web.personalization',
        false
      )

      searchProvider
        .get(req, apiQueryString, null, showWebPersonalization)
        .then(function (response) {
          /**
           * It's possible that we can have an error property on the response, and
           * it's possible that if it is JSON we want to handle the unserialized object.
           * In case of error swallow (sometimes it comes as error: undefined)
           */
          if (response.error) {
            try {
              response.error = JSON.parse(response.error)
              // eslint-disable-next-line no-empty
            } catch (e) {}
          }
          // check if the result is good, if not, then try
          // without a user token
          const { error, status } = response
          if (status === 200) {
            return response
          } else if (
            user.loggedIn &&
            (status === 403 ||
              (status === 400 && error && error.refreshed === false))
          ) {
            return searchProvider.get(null, apiQueryString, {
              authOptions: { skipAuth: true, deleteCookie: true },
              showWebPersonalization,
            })
          } else {
            throw new Error(
              'SEARCH_PLATFORM_API_ERROR: ' +
                'status_code: ' +
                response.status +
                ' response_body: ' +
                JSON.stringify(response.body || response.error || response)
            )
          }
        })
        .then(function (response) {
          const assets = response.body.data || []
          return resolve({
            assets,
            total: response.body.totalCount,
            count: response.body.totalElements,
            offset: response.body.currentPage,
            recommendedListingsForYou: response.body.recommendedListingsForYou,
          })
        })
        .catch(function (err) {
          // Add error so fe will display error message
          return resolve({
            errors: `SEARCH_API_ERROR: ${JSON.stringify(err)}`,
            status: err.status,
            statusText: err.statusText,
            assets: [],
          })
        })
    })
  },
}

export default searchService
